import * as Image from "../images";

export const items = [
  {
    img: Image.heroImage1,
    name: "Everests Inclusive Healthcare Hub",
    desc: "24 hour services",
  },
  {
    img: Image.heroImage2,
    name: "Everest Cares: Your Path to Wellness",
    desc: "Experienced Doctor",
  },
  {
    img: Image.heroImage3,
    name: "Start Your Health Journey Here",
    desc: "In the heart of the City",
  },
  {
    img: Image.heroImage4,
    name: "The Everest Hospital Pvt.Ltd",
    desc: "Clinical Excellence",
  },
  {
    img: Image.heroImage5,
    name: "Bridging Health with Compassion",
    desc: "Dedicated and Trained Staff",
  },
  {
    img: Image.heroImage6,
    name: "Healthcare That Cares",
    desc: "Advanced Technology",
  },
  {
    img: Image.heroImage7,
    name: "The Care You Deserve",
    desc: "Affordable Services",
  },
  {
    img: Image.heroImage8,
    name: "Wellness at Your Doorstep",
    desc: "Health Checkups Available",
  },
  {
    img: Image.heroImage9,
    name: "Your Health, Our Priority",
    desc: "Timely Consultation",
  },
  {
    img: Image.heroImage10,
    name: "Everest Healthcare Network",
    desc: "Collaborating for Better Care",
  },
  {
    img: Image.heroImage11,
    name: "Everest Family Health",
    desc: "Comprehensive Family Care",
  },
  {
    img: Image.heroImage12,
    name: "Expert Care for All Ages",
    desc: "Pediatric and Geriatric Services",
  },
  {
    img: Image.heroImage13,
    name: "The Best in Medical Expertise",
    desc: "Qualified Specialists",
  },
  {
    img: Image.heroImage14,
    name: "Everest Wellness Clinic",
    desc: "Preventative Health Care",
  },
  {
    img: Image.heroImage15,
    name: "Healing Hands of Everest",
    desc: "Compassionate Care",
  },
  {
    img: Image.heroImage16,
    name: "The Heart of Healthcare",
    desc: "Caring for Your Heart",
  },
  {
    img: Image.heroImage17,
    name: "Total Health Solutions",
    desc: "Full Range of Services",
  },
  {
    img: Image.heroImage18,
    name: "Cutting-edge Medical Care",
    desc: "Latest Medical Equipment",
  },
  {
    img: Image.heroImage19,
    name: "Your Wellness Journey Starts Here",
    desc: "Stay Healthy and Strong",
  },
  {
    img: Image.heroImage20,
    name: "Empowering Your Health",
    desc: "Holistic Health Approach",
  },
  {
    img: Image.heroImage21,
    name: "24/7 Care with a Personal Touch",
    desc: "Always Here for You",
  },
  {
    img: Image.heroImage22,
    name: "Caring Beyond Medicine",
    desc: "Focus on Emotional Well-being",
  },
  {
    img: Image.heroImage23,
    name: "Advanced Diagnostic Services",
    desc: "Early Detection Saves Lives",
  },
  {
    img: Image.heroImage24,
    name: "Modern Healthcare with a Personal Touch",
    desc: "We Understand Your Needs",
  },
  {
    img: Image.heroImage25,
    name: "Everest Health Partners",
    desc: "Collaboration for Better Care",
  },
  {
    img: Image.heroImage26,
    name: "Experience Quality Healthcare",
    desc: "Your Health is Our Mission",
  },
  {
    img: Image.heroImage27,
    name: "Leading the Way in Healthcare",
    desc: "Innovative Solutions for You",
  },
  {
    img: Image.heroImage28,
    name: "Health and Healing at Everest",
    desc: "Restoring Health, Renewing Hope",
  },
  {
    img: Image.heroImage29,
    name: "Pathway to Better Health",
    desc: "Transforming Health for All",
  },
  {
    img: Image.heroImage30,
    name: "Comprehensive Medical Care",
    desc: "Services for Every Need",
  },
  {
    img: Image.heroImage31,
    name: "At the Forefront of Medical Technology",
    desc: "Precision and Care",
  },
  {
    img: Image.heroImage32,
    name: "Where Health Comes First",
    desc: "Committed to Excellence",
  },
  {
    img: Image.heroImage33,
    name: "Focused on Your Wellness",
    desc: "Providing Expert Care",
  },
  {
    img: Image.heroImage34,
    name: "Your Health, Our Responsibility",
    desc: "Trusted Healthcare Providers",
  },
  {
    img: Image.heroImage35,
    name: "Everest Health and Wellness",
    desc: "For a Healthier Tomorrow",
  },
  {
    img: Image.heroImage36,
    name: "Revolutionizing Healthcare",
    desc: "Healthcare for a New Generation",
  },
  {
    img: Image.heroImage37,
    name: "Top-Notch Health Services",
    desc: "Serving You with Excellence",
  },
  {
    img: Image.heroImage38,
    name: "Affordable Healthcare for Everyone",
    desc: "Because Health Matters",
  },
  {
    img: Image.heroImage39,
    name: "Everest Health Institute",
    desc: "World-Class Medical Research",
  },
  {
    img: Image.heroImage40,
    name: "Health That Speaks Volumes",
    desc: "Quality Care at Its Best",
  },
  {
    img: Image.heroImage41,
    name: "Evolving Healthcare Solutions",
    desc: "Constant Innovation for You",
  },
  {
    img: Image.heroImage42,
    name: "Everest Community Health",
    desc: "Together for Better Health",
  },
  {
    img: Image.heroImage43,
    name: "Healing You with Expertise",
    desc: "Top-Quality Treatment",
  },
  {
    img: Image.heroImage44,
    name: "Your Wellness, Our Vision",
    desc: "Caring for You, Always",
  },
  {
    img: Image.heroImage45,
    name: "Your Health, Our Promise",
    desc: "Promises We Keep",
  },
  {
    img: Image.heroImage46,
    name: "Comprehensive Healthcare Services",
    desc: "Quality, Affordable Care",
  },
  {
    img: Image.heroImage47,
    name: "Building Healthy Futures",
    desc: "Committed to Better Health",
  },
  {
    img: Image.heroImage48,
    name: "Medical Excellence at Everest",
    desc: "Inspiring Confidence in Care",
  },
  {
    img: Image.heroImage49,
    name: "Nurturing Health and Well-being",
    desc: "Your Health, Our Focus",
  },
  {
    img: Image.heroImage50,
    name: "Achieving Health Together",
    desc: "Strong, Healthy Communities",
  },
];
