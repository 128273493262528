import { Trophy, CalendarDays, ScrollText, Newspaper } from "lucide-react";
export const navigation = [
  { title: "Home", path: "/", isDrapdown: false },
  { title: "Consultants", path: "/consultants", isDrapdown: false },
  { title: "Contact", path: "/contact", isDrapdown: false },
  { title: "Health Packages", path: "/health-packages", isDrapdown: false },
  { title: "Accrediations", path: "/accrediations", isDrapdown: false },
];

export const navigation1 = [
  { title: "Accrediations", path: "/accrediations", isDrapdown: false },
  { title: "Awards", path: "/awards", isDrapdown: false },
  { title: "Notice", path: "/notices", isDrapdown: false },
  { title: "Events", path: "/events", isDrapdown: false },
  {
    title: "Lab Report",
    path: "https://labreport.merodoctor.com/666",
    isDrapdown: false,
  },
];

export const overviews = [
  {
    title: "About Everest",
    href: "/about",
  },

  {
    title: "Our Mission & Vision",
    href: "/our-mission-vision",
  },
  {
    title: "Our Core Values",
    href: "/our-core-values",
  },

  {
    title: "Executive Comittee",
    href: "/executive-comittee",
  },

  {
    title: "Our Facilities",
    href: "/our-facilities",
  },
];

export const galleries = [
  {
    title: "Image",
    href: "/image",
  },

  {
    title: "Video",
    href: "/video",
  },
];

export const navItems = [
  {
    label: "News",
    to: "/news",
    icon: Newspaper,
    bgColor: "bg-[#5900A5]",
  },
  {
    label: "Awards",
    to: "/awards",
    icon: Trophy,
    bgColor: "bg-blue-600",
  },
  {
    label: "Events",
    to: "/events",
    icon: CalendarDays,
    bgColor: "bg-purple-600",
  },
  {
    label: "Notice",
    to: "/notices",
    icon: ScrollText,
    bgColor: "bg-teal-800",
  },
  {
    label: "Lab Report",
    to: "https://labreport.merodoctor.com/666",
    icon: ScrollText,
    bgColor: "bg-orange-700",
    external: true,
  },
];
