import { useState, useEffect } from "react";
import { Spinner } from "./import";
import { AxiosInstance } from "../config";

export default function CoreValues() {
  const [comittees, setcomittees] = useState([]);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        setFetching(true);
        const res = await AxiosInstance.get("/values");
        setcomittees(res.data.data);
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };
    fetchAll();
  }, []);

  return (
    <div className="">
      <div className="max-w-xl ">
        <p className=" leading-relaxed tracking-wide text-sm text-gray-600">Committed to ethical, vigorous, and empathetic healthcare with respect, excellence, ensuring satisfaction, and promoting effective teamwork for optimal patient well-being</p>
      </div>
      <div className="mt-12">
        <ul className="grid grid-cols-auto-fit-300 gap-4 ">
          {comittees.map((item: any, idx) => (
            <li
              key={idx}
              className="space-y-3 shadow-md p-4">
              <div className="h-14 w-14 p-2 border text-primary-600 rounded-lg flex items-center justify-center ">
                <img
                  src={item?.image}
                  alt="img"
                />
              </div>
              <h4 className=" text-gray-700 font-semibold">{item?.name}</h4>
              <p className=" leading-relaxed tracking-wide text-gray-600 text-sm ">{item?.description}</p>
            </li>
          ))}

          {fetching && (
            <div className=" flex items-center justify-center">
              <Spinner />
            </div>
          )}
        </ul>
      </div>
    </div>
  );
}
