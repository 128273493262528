import { useEffect, useState } from "react";
import { AxiosInstance } from "../../config";
import { Spinner, Heading } from "../../components/import";
import * as Image from "../../images";


export default function HealthPackages() {
  // fetch all
  const [comittees, setcomittees] = useState([]);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        setFetching(true);
        const res = await AxiosInstance.get("/health-packages");
        setcomittees(res.data.data);
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };
    fetchAll();
  }, []);

  return (
    <div className="layout">
      <Heading title="Health Packages" />
      <p className="w-full  lg:w-6/12 text-gray-600 mt-2 tracking-wide leading-normal">Explore comprehensive health packages tailored to your needs. Our offerings include wellness checkups, diagnostic tests, and personalized services for holistic health management.</p>
      <div>
        {fetching ? (
          <div className=" flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <ul className="mt-16 grid gap-8 sm:grid-cols-2 lg:grid-cols-3 bg">
            {comittees?.map((item: any, index) => (
              <li
                key={index}
                // className={` shadow-sm p-4 rounded-lg ${getRandomColor()}`}>
                className=" shadow-sm p-4 rounded-lg bg-blue-500 text-gray-200 ">
                <div className=" flex flex-col  ">
                  <h4 className="text-gray-200 tracking-wide text-xl font-semibold text-center mb-2">{item.title}</h4>
                  <div className=" flex justify-center opacity-90">
                    <img
                      src={Image.kit}
                      alt="img"
                      className=" h-12 w-12 mb-2"
                    />
                  </div>
                  <div className=" flex mb-2 ">
                    <p className="text-gray-700 tracking-wide bg-yellow-300 px-2 rounded-full"> Total Rate : Rs. {item.totalRate}</p>
                  </div>
                  <div className=" flex ">
                    <p className="text-gray-700 tracking-wide  bg-green-300 px-2 rounded-full  ">Offer Rate : Rs. {item.packageRate}</p>
                  </div>
                </div>
                <hr className=" pb-4 mt-2 " />
                <div className=" grid grid-cols-2 text-sm gap-2 ">
                  {item.item1 && <p> - {item.item1}</p>}
                  {item.item2 && <p> - {item.item2}</p>}
                  {item.item3 && <p> - {item.item3}</p>}
                  {item.item4 && <p> - {item.item4}</p>}
                  {item.item5 && <p> - {item.item5}</p>}
                  {item.item6 && <p> - {item.item6}</p>}
                  {item.item7 && <p> - {item.item7}</p>}
                  {item.item8 && <p> - {item.item8}</p>}
                  {item.item9 && <p> - {item.item9}</p>}
                  {item.item10 && <p> - {item.item10}</p>}
                  {item.item11 && <p> - {item.item11}</p>}
                  {item.item12 && <p> - {item.item12}</p>}
                  {item.item13 && <p> - {item.item13}</p>}
                  {item.item14 && <p> - {item.item14}</p>}
                  {item.item15 && <p> - {item.item15}</p>}
                  {item.item16 && <p> - {item.item16}</p>}
                  {item.item17 && <p> - {item.item17}</p>}
                  {item.item18 && <p> - {item.item18}</p>}
                  {item.item19 && <p> - {item.item19}</p>}
                  {item.item20 && <p> - {item.item20}</p>}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
