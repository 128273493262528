import { useEffect, useState } from "react";
import parse from "html-react-parser";
import { formatDate } from "../../helper/formatDate";
import { Link } from "react-router-dom";
import { AxiosInstance } from "../../config";
import { Heading } from "../../components/import";

interface events {
  createdAt: string;
}

export default function Event() {
  const [comittees, setcomittees] = useState([]);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        const res = await AxiosInstance.get("/events");
        const sortedEvent = res.data.data.sort((a: events, b: events) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        setcomittees(sortedEvent);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAll();
  }, []);

  return (
    <section className="layout">
      <Heading title="Our Latest Events" />
      <div className="max-w-md">
        <p className="text-gray-600 mt-2">Discover and join our latest events, where community and engagement thrive.</p>
      </div>
      <ul className="mt-16 grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3  3xl:grid-cols-4">
        {comittees?.map((item: any, idx) => (
          <Link
            to={`/events/${item._id}`}
            key={idx}
            className="border rounded-lg shadow-md">
            <div className="p-4">
              <div className="relative h-48">
                <img
                  src={item?.image}
                  alt="img"
                  className="object-contain w-full h-full"
                />
              </div>
              <hr className="my-2" />
              <h4 className="text-gray-600 mt-2 text-xs text-end ">{formatDate(item.createdAt)}</h4>
              <h4 className="text-gray-800 mt-1 text-lg ">{item.title}</h4>
              <h4 className="text-gray-600 mt-2 text-sm font-light">{parse(item?.description.slice(0, 60))}</h4>
            </div>
          </Link>
        ))}
      </ul>
    </section>
  );
}
