import { CoreValues, Heading } from "../../components/import";

export default function OurColeValues() {
  return (
    <div className="layout md:px-10">
      <Heading title="Our Core Values " />
      <CoreValues />
    </div>
  );
}
