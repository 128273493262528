import { Clock, LocateIcon, Mail, Phone } from "lucide-react";
import { useEffect, useState } from "react";
import { AxiosInstance } from "../../config";
import { Heading } from "../../components/import";

interface ContactInfoItemProps {
  title: string;
  icon: JSX.Element;
  value: string | undefined;
}

const ContactInfoItem: React.FC<ContactInfoItemProps> = ({ title, icon, value }) => (
  <div>
    <h4 className="text-gray-600 font-medium">{title}</h4>
    <div className="mt-3 flex items-center gap-x-2">
      <span className="text-primary-600 animate-bounce">{icon}</span>
      <p className="text-sm">{value}</p>
    </div>
  </div>
);
export default function Contact() {
  // fetch all
  const [contactDetail, setContactDetail] = useState<any>();
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        setFetching(true);
        const res = await AxiosInstance.get("/contact-details");
        setContactDetail(res.data.data[0]);
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };
    fetchAll();
  }, []);

  // console.log(contactDetail);
  // const email = contactDetail?.email;
  // console.log(fetching)

  return (
    <section
      id="contact"
      className=" text-gray-600 layout">
      <div className=" space-y-3">

        <Heading title="Contact" />
        <p className="text-gray-700 text-3xl font-semibold sm:text-4xl">Let us know how we can help</p>
        <p className=" text-gray-600">We’re here to help and answer any question you might have, We look forward to hearing from you .</p>
      </div>
      {fetching ? (
        <div className="flex justify-center items-center mt-12">
          <span className="text-xl text-gray-600">Loading...</span>
        </div>
      ) : (

        contactDetail && <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mt-12">
          <ContactInfoItem
            title="Emergency Hotline"
            icon={<Phone className="text-xl text-red-700" />}
            value={contactDetail?.emergencyHotline}
          />
          <ContactInfoItem
            title="Emergency Ambulance Hotline"
            icon={<Phone className="text-xl text-red-700" />}
            value={contactDetail?.emergencyAmbulanceHotline}
          />
          <ContactInfoItem
            title="Appointment Number"
            icon={<Phone className="text-xl text-red-700" />}
            value={contactDetail?.appointmentNumber}
          />
          <ContactInfoItem
            title="Suchana adhikrit"
            icon={<Phone className="text-xl text-red-700" />}
            value={contactDetail?.administrationNumber}
          />
          <ContactInfoItem
            title="24 Hour Available"
            icon={<Clock className="text-xl text-red-700" />}
            value={contactDetail?.twentyfourHourAvailableNumber}
          />
          <ContactInfoItem
            title="Email"
            icon={<Mail className="text-xl text-red-700" />}
            value={contactDetail?.email}
          />
          <ContactInfoItem
            title="Location"
            icon={<LocateIcon className="text-xl text-red-700" />}
            value={contactDetail?.location}
          />
        </div>
      )
      }

      {/* <div className=" grid grid-cols-1 lg:grid-cols-2 mt-20 gap-4">
        <div>
          <h3 className="text-primary-600 font-semibold mb-4">Find us</h3>
          <p className="text-gray-700 text-3xl font-semibold sm:text-4xl">Our Building</p>
          <p className=" text-gray-600 leading-relaxed tracking-wide mt-3 text-justify">Everest Hospital is a hospital in Kathmandu, Bagmati Province. Everest Hospital is situated nearby to Nature Care Hospital and the health club Beauty & The Beast Fitness Station.</p>
        </div>

        <div>
          <img className=" rounded-md"
            src={Image.everestwel}
            alt="img"
            height={1000}
            width={1000}
          />
        </div>
      </div> */}

      <div className=" mt-16">
        <h3 className="text-primary-600 font-semibold mb-5">Locate us</h3>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56526.09743406524!2d85.23089215993514!3d27.690069849418244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19bdde43f10d%3A0x9b829a41b660f0d8!2sEverest%20Hospital%20Pvt.%20Ltd.!5e0!3m2!1sen!2snp!4v1701347284601!5m2!1sen!2snp"
          className=" w-full h-96"
          loading="lazy"
        />
      </div>
    </section>
  );
}
