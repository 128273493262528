import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { AxiosInstance } from "../../config";
import { X } from "lucide-react";

interface Image {
  createdAt: string;
}
export default function Gallery() {
  // fetch all
  const [images, setImages] = useState<Image[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        const res = await AxiosInstance.get("/galleries");
        // Assuming each image has a `createdAt` field, which is a string or Date
        const sortedImages = res.data.data.sort((a: Image, b: Image) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        setImages(sortedImages);
      } catch (error) {
        // Handle error
      }
    };
    fetchAll();
  }, []);

  return (
    <>
      <section className="layout">
        <h3 className="text-primary-600 font-semibold mt-4">Gallery</h3>
        <p className="w-full  lg:w-6/12 text-gray-600 mt-2 tracking-wide leading-normal">
          Explore our comprehensive gallery showcasing hospital facilities,
          dedicated staff, and impactful moments in healthcare excellence.
        </p>
        <div className="grid grid-cols-auto-fit-300 gap-6 mt-16">
          {images.map((item: any, index: number) => (
            <div key={index} className="h-[300px]">
              <img
                src={item?.image}
                alt="img"
                className="xl:hover:scale-110 duration-[5s] rounded-xl object-cover w-full h-full"
                onClick={() => setSelectedImage(item?.image)}
              />
            </div>
          ))}
        </div>
      </section>
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
          <div className="relative bg-white p-4 rounded-lg shadow-lg max-w-2xl w-full">
            <button
              className="absolute top-2 right-2 text-white bg-red-500 text-xl rounded-full"
              onClick={() => setSelectedImage(null)}
            >
              <X size={26} />
            </button>
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full h-auto rounded-lg"
            />
          </div>
        </div>
      )}
    </>
  );
}
