import { FiPhoneCall } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import * as Image from "../images";
export default function HelpLine() {
  return (
    <div className=" layout flex flex-col">
      <div className="   bg-primary-500 md:px-20 px-2   md:flex items-center justify-between">
        <div
          data-aos="fade-up"
          data-aos-duration="3000"
          className="  flex flex-col gap-8  w-full md:w-6/12"
        >
          <div className=" md:mt-0 mt-10 text-xl   text-gray-100 font-semibold md:text-2xl cursor-pointer">
            HELP LINE
          </div>
          <div className=" text-3xl  md:text-5xl   text-gray-100 cursor-pointer">
            24/7 Helpline Services
          </div>
          <div className=" text-xl text-gray-300">
            Everest Hospital: Central, accessible healthcare, affordable and
            integrated services. State-of-the-art facilities, experienced
            physicians create a warm environment for comprehensive care.
          </div>
          <div className=" md:flex gap-16">
            <div className=" flex items-center gap-7">
              <span className="  animate-bounce bg-[#4850D4] text-gray-100 rounded-full p-2">
                <FiPhoneCall size={25} />
              </span>
              <div>
                <h1 className=" t text-xl  text-gray-300 ">Our Number</h1>
                <span className=" text-gray-200"> 01-4795177</span>
              </div>{" "}
            </div>

            <div className="  md:mt-0 mt-4 flex items-center gap-7">
              <span className=" animate-bounce bg-[#4850D4] text-gray-100 rounded-full p-2">
                <HiOutlineMail size={25} />
              </span>
              <div>
                <h1 className=" t text-xl  text-gray-300 ">Appointment</h1>
                <span className=" text-gray-200">
                  {" "}
                  everesthospital@gmail.com
                </span>
              </div>{" "}
            </div>
          </div>
        </div>
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="2500"
          className=" w-full md:w-6/12 flex justify-end"
        >
          <img
            className=" rounded-2xl hover:rotate-3 duration-500  object-cover md:mt-3 mt-4 px-4 mb-3"
            height={550}
            width={550}
            src={Image.nur14}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
