import { useState, useEffect } from "react";
import { AxiosInstance } from "../config";
import { Heading, Spinner } from "./import";

export default function OurFacilitiesSection() {
  const [comittees, setcomittees] = useState([]);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        setFetching(true);
        const res = await AxiosInstance.get("/facilities");
        setcomittees(res.data.data);
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };
    fetchAll();
  }, []);

  return (
    <section className="layout">
      <Heading title="Our Facilities" />
      <div className="max-w-xl ">
        <p className=" leading-relaxed tracking-wide text-sm text-gray-600">Discover our exceptional facilities designed for your well-being and comfort. From cutting-edge medical technology to serene environments, we prioritize your care and satisfaction.</p>
      </div>
      <ul className="grid gap-12 grid-cols-auto-fit-200 mt-12">
        {comittees.map((item: any, idx) => (
          <li
            key={idx}
            className="  hover:scale-105 duration-700 cursor-pointer flex flex-col gap-4 items-center border p-4 rounded-md shadow-md  border-gray-100 hover:border-primary-200 transition-all ease-in-out ">
            <div className=" w-10 h-10 ">
              <img
                src={item?.image}
                alt="img"
              />
            </div>
            <div>
              <h4 className=" font-medium text-gray-700 text-center ">{item?.title}</h4>
              <p className=" tracking-wide text-sm  text-gray-600 text-center">{item?.description}</p>
            </div>
          </li>
        ))}

        {fetching && (
          <div className=" flex items-center justify-center">
            <Spinner />
          </div>
        )}
      </ul>
    </section >
  );
}
