import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { AxiosInstance } from "../config";

interface GalleryItem {
  image: string;
  createdAt: string;

}

export default function HealthCare() {
  const [images, setImages] = useState<GalleryItem[]>([]);
  const [images1, setImages1] = useState<GalleryItem[]>([]);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        const res = await AxiosInstance.get("/galleries");
        const latestImages = [...res.data.data].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        setImages(latestImages);

        const oldestImages = [...res.data.data].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        setImages1(oldestImages);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };
    fetchAll();
  }, []);
  return (
    <div className="layout">
      <div className="py-14">
        <div className=" w-full">
          <div className="max-w-2xl mx-auto sm:text-center">
            <h3 className="text-gray-800 text-3xl font-semibold tracking-widest sm:text-4xl">
              Health Care Solutions
            </h3>
            <p className="text-gray-600 mt-3 text-justify">
              Everest Hospital: Central, accessible healthcare, affordable and
              integrated services. State-of-the-art facilities, experienced
              physicians create a warm environment for comprehensive care.
            </p>
          </div>
          <Marquee speed={20} className="mt-12">
            <div className="flex">
              {images.map((item, idx) => (
                <div key={idx}>
                  <div className="  w-full h-60 sm:h-52 md:h-72">
                    <img
                      src={item.image}
                      className=" hover:opacity-70 hover:scale-95 duration-700 w-full h-full object-cover object-center"
                      alt=""
                    />
                  </div>
                </div>
              ))}
            </div>
          </Marquee>
          <Marquee direction="right" speed={20}>
            <div className="flex">
              {images1.map((item, idx) => (
                <div key={idx}>
                  <div className="  w-full h-60 sm:h-52 md:h-72">
                    <img
                      src={item.image}
                      className=" hover:opacity-70 hover:scale-95 duration-700 w-full h-full object-cover object-center"
                      alt=""
                    />
                  </div>
                </div>
              ))}
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
}
