import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
     const [contentHeight, setContentHeight] = useState("100vh");

     useEffect(() => {
          const updateContentHeight = () => {
               const navbar = document.querySelector("nav");
               const footer = document.querySelector("footer");
               const navbarHeight = navbar ? navbar.offsetHeight : 0;
               const footerHeight = footer ? footer.offsetHeight : 0;
               const newHeight = `calc(100vh - ${navbarHeight}px - ${footerHeight}px)`;
               setContentHeight(newHeight);
               console.log("Navbar", navbarHeight)
               console.log("Div", newHeight)
               console.log("footer", footerHeight)
          };

          updateContentHeight();
          window.addEventListener("resize", updateContentHeight);

          return () => window.removeEventListener("resize", updateContentHeight);
     }, []);

     return (
          <div className="border-gray-200 dark:bg-white-900 top-0 layout">
               <div
                    style={{ minHeight: contentHeight }}
                    className="flex items-center justify-center paddingcontainer italic"
               >
                    <div className="text-[#1a005c] flex flex-col items-center justify-center px-4 text-center">
                         <h1 className="text-7xl font-bold mb-4">404</h1>
                         <h2 className="text-3xl font-semibold mb-2">Page Not Found</h2>
                         <p className="text-lg mb-6">
                              Oops! The page you're looking for doesn't exist or has been moved.
                         </p>
                         <Link
                              to="/"
                              className="text-white bg-[#1a005c] px-6 py-3 rounded-md font-medium hover:bg-[#090020] transition duration-300"
                         >
                              Back to Home
                         </Link>
                    </div>
               </div>
          </div>
     );
};

export default NotFound;