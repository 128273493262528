import {
  BookA, ChevronDown, Menu, ChevronUp, Phone, Plane, Siren, Stethoscope, X,
} from "lucide-react";
import { useContext, useEffect, useRef, useState } from "react";
import * as Image from "../../images";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { CharityContext } from "../../context/context";
import { AxiosInstance } from "../../config";
import { galleries, navigation, navigation1, navItems, overviews } from "../../data/navbar";

export default function Navbar() {
  const [state, setState] = useState(false);
  const [drapdownState, setDrapdownState] = useState<any>({
    isActive: false,
    idx: null,
  });
  const location = useLocation();
  const pathname = location.pathname;
  useEffect(() => {
    document.onclick = (e) => {
      const target: any = e.target;
      if (!target.closest(".nav-menu"))
        setDrapdownState({ isActive: false, idx: null });
    };
  }, []);

  const modal1Ref = useRef<any>();
  const [openDepartment1, setopenDepartment1] = useState(false);

  const handleOutsideClick1: any = (event: React.MouseEvent) => {
    if (
      modal1Ref.current &&
      !modal1Ref.current.contains(event.target as Node)
    ) {
      setopenDepartment1(false);
    }
  };

  useEffect(() => {
    if (openDepartment1) {
      document.addEventListener("mousedown", handleOutsideClick1);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick1);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick1);
    };
  }, [openDepartment1]);

  const [showTopBar, setShowTopBar] = useState(true);
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setShowTopBar(scrollPosition <= 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { contactDetail } = useContext(CharityContext);
  const [showDepartment, setshowDepartment] = useState(false);
  const departmentRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        departmentRef.current &&
        !departmentRef.current.contains(event.target)
      ) {
        setshowDepartment(false);
      }
    };

    const handleEscapeKey = (event: any) => {
      if (event.key === "Escape") {
        setshowDepartment(false);
      }
    };

    if (showDepartment) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleEscapeKey);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [showDepartment]);

  const handleDepartment = () => {
    setshowDepartment(!showDepartment);
  };

  // fetch all
  const [comittees, setcomittees] = useState([]);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        const res = await AxiosInstance.get("/departments/less-data");
        setcomittees(res.data.data);

      } catch (error) { }
    };
    fetchAll();
  }, []);

  // console.log(comittees);

  const [openSmallDeviceOverview, setOpenSmallDeviceOverview] = useState(false);
  const handleSmallDeviceOverview = () => {
    setOpenSmallDeviceOverview(!openSmallDeviceOverview);
  };

  const [openSmallDeviceGallery, setOpenSmallDeviceGallery] = useState(false);
  const handleSmallDeviceGallery = () => {
    setOpenSmallDeviceGallery(!openSmallDeviceGallery);
  };

  return (
    <>
      <nav
        className={`relative overflow-hidden bg-primary-700 3xl:bg-transparent flex flex-row 3xl:flex-col z-20 w-full 3xl:static 3xl:text-sm 3xl:border-none ${state ? "shadow-lg  3xl:shadow-none" : ""
          }`}
      >
        {showTopBar && (
          <div className="sticky top-0 z-50 top_nav_bar hidden 3xl:flex items-center justify-between px-10   layout">
            <div className=" 3xl:flex flex-col hidden">
              <Link to={"/"} className="hidden xl:flex gap-2 items-center space-x-2  p-2 rounded-lg">
                <div className="w-[100px] h-[80px] flex-shrink-0">
                  <img
                    src={Image.logoev}
                    alt="Everest Hospital Logo"
                    className="w-full h-full object-fill"
                  />
                </div>

                {/* Text Content */}
                <div className="flex flex-col gap-2 justify-between h-full text-justify">
                  <span className="text-red-600 font-bold text-xl">
                    एभरेष्ट अस्पताल प्रा.  लि.
                  </span>
                  <span className="text-primary-500 font-black text-[38px]">
                    EVEREST
                  </span>
                  <span className="bg-red-600 px-1 font-bold py-1 text-white text-[17px]">
                    HOSPITAL PVT. LTD.
                  </span>
                </div>
              </Link>
            </div>

            <div className="flex gap-5 ">
              {navItems.map((item, index) => {
                const Icon = item.icon;
                return (
                  <div key={index} className="hidden 3xl:block ">
                    <Link
                      to={item.to}
                      target={item.external ? "_blank" : "_self"}
                      className=" px-6 py-4 cursor-pointer  "
                    >
                      <div className="border-r-[2px] flex items-center gap-4 px-2">
                        <p className={`text-gray-100 p-2 rounded-lg ${item.bgColor}`}>
                          <Icon className="text-[300px]" />
                        </p>
                        <p className="text-xl">{item.label}</p>
                      </div>
                    </Link>
                  </div>


                );
              })}
            </div>
          </div>
        )}

        <div className=" items-center px-4 sm:px-8 py-3 3xl:py-0 3xl:px-0  mx-auto 3xl:flex    layout">
          <div className="flex   items-center justify-between  3xl:block">
            <Link to={"/"} className=" flex 3xl:hidden gap-2 justify-center items-center">
              <img src={Image.logoev} alt="logo" className=" h-9  rounded-md" />
              <h1 className=" text-gray-100  italic">
                EVEREST HOSPITAL PVT.LTD
              </h1>
            </Link>
            <div className="3xl:hidden">
              <button
                className="text-gray-200"
                onClick={() => setState(!state)}
              >
                {state ? <X size={26} /> : <Menu size={26} />}
              </button>
            </div>
          </div>
          <div
            className={`nav-menu flex-1 pb-3 mt-3 3xl:block 3xl:pb-0 3xl:mt-0 ${state ? "block" : "hidden"
              }`}
          >
            <ul className="flex flex-col 3xl:flex-row items-start 3xl:items-center justify-start 3xl:justify-center bg-primary-700 3xl:py-8 py- px-4 rounded-[40px] space-y-6 3xl:space-y-0 3xl:space-x-10 h-[80vh] 3xl:h-auto overflow-y-auto 3xl:overflow-visible">
              <div className=" hidden  3xl:block ">
                <div className="dropdown inline-block ">
                  <button className=" text-gray-200   rounded inline-flex items-center">
                    <span className="mr-1 whitespace-nowrap">
                      Hospital Overview
                    </span>
                    <ChevronDown size={16} />
                  </button>

                  <ul className="  dropdown-menu absolute hidden text-gray-200  bg-primary-500  pt-4  pb-2">
                    {overviews?.map((item: any, index: number) => (
                      <div key={index} className=" flex flex-col">
                        <Link
                          to={item.href}
                          key={item._id}
                          className=" cursor-pointer hover:bg-gray-200 hover:text-gray-700 hover:font-medium p-2 hover:tracking-wide transition-all ease-in-out w-52"
                        >
                          {item.title}
                        </Link>
                        <hr className=" mb-2 opacity-20" />
                      </div>
                    ))}
                  </ul>
                </div>
              </div>

              <div className=" block 3xl:hidden">
                <p
                  onClick={handleSmallDeviceOverview}
                  className=" text-gray-300 font-medium flex items-center gap-1"
                >
                  Hospital Overview <ChevronDown size={16} />{" "}
                </p>
                {openSmallDeviceOverview && (
                  <div className=" text-gray-200   pt-4  pb-2">
                    {overviews?.map((item: any, index: number) => (
                      <div key={index} className=" flex flex-col">
                        <Link
                          to={item.href}
                          key={item._id}
                          onClick={() => {
                            setOpenSmallDeviceOverview(false);
                            setState(false);
                          }}
                          className=" cursor-pointer hover:bg-gray-200 hover:text-gray-700 hover:font-medium p-2 hover:tracking-wide transition-all ease-in-out w-52"
                        >
                          {item.title}
                        </Link>
                        <hr className=" mb-2 opacity-20" />
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {navigation.map((item: any, idx: any) => {
                return (
                  <li key={idx}>
                    {item.isDrapdown ? (
                      <button
                        className="w-full flex items-center justify-between gap-1 text-gray-200 "
                        onClick={() =>
                          setDrapdownState({
                            idx,
                            isActive: !drapdownState.isActive,
                          })
                        }
                      >
                        {item.title}
                        {drapdownState.idx === idx && drapdownState.isActive ? (
                          <ChevronUp size={16} />
                        ) : (
                          <ChevronDown size={16} />
                        )}
                      </button>
                    ) : (
                      <a
                        href={item.path}
                        className={`${pathname === item.path ? "underline  font-medium" : ""
                          } block text-gray-200 `}
                      >
                        {item.title}
                      </a>
                    )}
                  </li>
                );
              })}

              <div className=" hidden 3xl:block">
                <div className="dropdown inline-block ">
                  <button className=" text-gray-200    rounded inline-flex items-center">
                    <span className="mr-1 whitespace-nowrap">Gallery</span>
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                    </svg>
                  </button>
                  <ul className="dropdown-menu absolute hidden text-gray-200  bg-primary-500  pt-4  pb-2">
                    {galleries?.map((item: any, index: number) => (
                      <div key={index} className=" flex flex-col">
                        <Link
                          to={item.href}
                          key={item._id}
                          className=" cursor-pointer hover:bg-gray-200 hover:text-gray-700 hover:font-medium p-2 hover:tracking-wide transition-all ease-in-out w-52"
                        >
                          {item.title}
                        </Link>
                        <hr className=" mb-2 opacity-20" />
                      </div>
                    ))}
                  </ul>
                </div>
              </div>

              <div className=" block 3xl:hidden">
                <p
                  onClick={handleSmallDeviceGallery}
                  className=" text-gray-300 font-medium flex items-center gap-1"
                >
                  Gallery <ChevronDown size={16} />{" "}
                </p>
                {openSmallDeviceGallery && (
                  <div className=" text-gray-200  bg-primary-500  pt-4  pb-2">
                    {galleries?.map((item: any, index: number) => (
                      <div key={index} className=" flex flex-col">
                        <Link
                          to={item.href}
                          key={item._id}
                          onClick={() => {
                            setOpenSmallDeviceGallery(false);
                            setState(false);
                          }}
                          className=" cursor-pointer hover:bg-gray-200 hover:text-gray-700 hover:font-medium p-2 hover:tracking-wide transition-all ease-in-out w-52"
                        >
                          {item.title}
                        </Link>
                        <hr className=" mb-2 opacity-20" />
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="">
                <button
                  onMouseEnter={handleDepartment}
                  className=" flex text-gray-200 items-center"
                >
                  {" "}
                  Departments <ChevronDown size={16} />
                </button>

                {showDepartment && (
                  <div
                    ref={departmentRef}
                    className=" absolute bg-primary-500 p-4"
                  >
                    <div className=" flex">
                      <span
                        className=" text-gray-200 font-semibold text-xl mb-4 bg-blue-500 p-2 rounded-sm">
                        Our Departments
                      </span>
                    </div>
                    <div className=" grid grid-cols-1  gap-2 h-52 overflow-y-scroll">
                      {comittees?.map((item: any) => (
                        <Link
                          to={`/departments/${item._id}`}
                          onClick={() => {
                            setshowDepartment(false);
                            setState(false);
                          }}
                          key={item._id}
                          className=" flex flex-col"
                        >
                          <p className=" text-gray-200 hover:bg-gray-300 p-2 cursor-pointer hover:text-gray-800 hover:font-medium transition-all ease-in-out hover:tracking-wide">
                            {item.name}
                          </p>
                          <hr className=" opacity-20" />
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              {openDepartment1 && (
                <div className="mt-6 inset-x-0  flex relative 3xl:absolute bg-primary-500 w-52 right-28 ">
                  <ul className="flex flex-col gap-2   right-56 ">
                    <Link to={"/about"} className="">
                      <p className="text-sm text-gray-200  cursor-pointer hover:bg-primary-400 hover:text-white px-4 py-1 tracking-wider transition-all ease-in-out">
                        {" "}
                        About
                      </p>
                    </Link>
                    <Link to={"/executive-comittee"} className="">
                      <p className="text-sm text-gray-200  cursor-pointer hover:bg-primary-400 hover:text-white px-4 py-1 tracking-wider transition-all ease-in-out">
                        Executive Comittee
                      </p>
                    </Link>
                    <Link to={"/accrediations"} className="">
                      <p className="text-sm text-gray-200  cursor-pointer hover:bg-primary-400 hover:text-white px-4 py-1 tracking-wider transition-all ease-in-out">
                        {" "}
                        Accrediations
                      </p>
                    </Link>
                    <Link to={"/awards"} className="">
                      <p className="text-sm text-gray-200  cursor-pointer hover:bg-primary-400 hover:text-white px-4 py-1 tracking-wider transition-all ease-in-out">
                        {" "}
                        Awards & Recognition
                      </p>
                    </Link>
                  </ul>
                </div>
              )}

              <div
                className={` nav-menu flex-1 pb-3 mt-8  3xl:pb-0 3xl:mt-0 ${state ? "block" : "hidden"
                  }`}
              >
                <ul className="items-center space-y-6 3xl:flex 3xl:space-x-6 3xl:space-y-0  3xl:h-8 overflow-y-scroll 3xl:overflow-hidden">
                  {navigation1.map((item: any, idx: any) => {
                    return (
                      <li key={idx}>
                        {item.isDrapdown ? (
                          <button
                            className="w-full flex items-center justify-between gap-1 text-gray-200 "
                            onClick={() =>
                              setDrapdownState({
                                idx,
                                isActive: !drapdownState.isActive,
                              })
                            }
                          >
                            {item.title}
                            {drapdownState.idx === idx &&
                              drapdownState.isActive ? (
                              <ChevronUp size={16} />
                            ) : (
                              <ChevronDown size={16} />
                            )}
                          </button>
                        ) : (
                          <a
                            href={item.path}
                            className={`${pathname === item.path
                              ? "underline  font-medium"
                              : ""
                              } block text-gray-200 `}
                          >
                            {item.title}
                          </a>
                        )}
                        {item.isDrapdown &&
                          drapdownState.idx === idx &&
                          drapdownState.isActive ? (
                          <div className="mt-6 inset-x-0 top-14 w-full 3xl:absolute bg-primary-500  3xl:border-y 3xl:shadow-md 3xl:mt-0">
                            <ul className="max-w-screen-xl mx-auto grid items-center gap-6 3xl:p-8 3xl:grid-cols-2 lg:grid-cols-3">
                              <div>
                                <div className="flex gap-3 items-center">
                                  <Siren size={18} className=" text-gray-100" />
                                  <div className=" flex flex-col">
                                    <span className="text-gray-100 duration-200 group- text-sm font-medium 3xl:text-base">
                                      Emergency Hotline
                                    </span>
                                    <a
                                      href={`tel:${contactDetail?.emergencyHotline}`}
                                      className="text-sm text-gray-200 group-hover:text-gray-800 mt-1"
                                    >
                                      {contactDetail?.emergencyHotline}
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className="flex gap-3 items-center">
                                  <Siren size={18} className=" text-gray-100" />
                                  <div className=" flex flex-col">
                                    <span className="text-gray-100 duration-200 group- text-sm font-medium 3xl:text-base">
                                      Emergency Ambulance Hotline
                                    </span>
                                    <a
                                      href={`tel:${contactDetail?.emergencyAmbulanceHotline}`}
                                      className="text-sm text-gray-200 group-hover:text-gray-800 mt-1"
                                    >
                                      {contactDetail?.emergencyAmbulanceHotline}
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className="flex gap-3 items-center">
                                  <Phone size={18} className=" text-gray-100" />
                                  <div className="flex flex-col">
                                    <span className="text-gray-100 duration-200 group- text-sm font-medium 3xl:text-base">
                                      Hospital Number
                                    </span>
                                    <a
                                      href={`tel:${contactDetail?.hospitalNumber}`}
                                      className="text-sm text-gray-200 group-hover:text-gray-800 mt-1"
                                    >
                                      {contactDetail?.hospitalNumber}
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className="flex gap-3 items-center">
                                  <Plane size={18} className=" text-gray-100" />
                                  <div className=" flex flex-col">
                                    <span className="text-gray-100 duration-200 group- text-sm font-medium 3xl:text-base">
                                      Heli Rescue Number
                                    </span>
                                    {contactDetail?.heliRescueNumber && (
                                      <a
                                        href={`tel:${contactDetail?.heliRescueNumber}`}
                                        className="text-sm text-gray-200 group-hover:text-gray-800 mt-1"
                                      >
                                        {contactDetail?.heliRescueNumber}
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className="flex gap-3 items-center">
                                  <BookA size={18} className=" text-gray-100" />
                                  <div className=" flex flex-col">
                                    <span className="text-gray-100 duration-200 group- text-sm font-medium 3xl:text-base">
                                      Appointmnt Number
                                    </span>
                                    <a
                                      href={`tel:${contactDetail?.appointmentNumber}`}
                                      className="text-sm text-gray-200 group-hover:text-gray-800 mt-1"
                                    >
                                      {contactDetail?.appointmentNumber}
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className="flex gap-3 items-center">
                                  <Stethoscope
                                    size={18}
                                    className=" text-gray-100"
                                  />
                                  <div className=" flex flex-col">
                                    <span className="text-gray-100 duration-200 group- text-sm font-medium 3xl:text-base">
                                      Adminintration Number
                                    </span>
                                    <a
                                      href={`tel:${contactDetail?.administrationNumber}`}
                                      className="text-sm text-gray-200 group-hover:text-gray-800 mt-1"
                                    >
                                      {contactDetail?.administrationNumber}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </ul>
                          </div>
                        ) : (
                          ""
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </ul>
          </div>
        </div>

      </nav>

      {state ? (
        <div
          className="z-10 fixed top-0 w-screen h-screen bg-black/20 backdrop-blur-sm 3xl:hidden"
          onClick={() => setState(false)}
        ></div>
      ) : (
        ""
      )}
    </>
  );
}

