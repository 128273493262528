// import everestwel from "../assets/nurse/everestwel.jpg";
import * as Image from "../images";

import Typewriter from "typewriter-effect";

const Welcome = () => {
  return (
    <div className="layout">
      <div className="relative bg-gradient-to-tr  h-[16vh] w-full">
        {/* <img
          src={Image.everestwel}
          alt=""
          className="object-cover absolute w-full h-full mix-blend-overlay"
        /> */}

        {/* Centered Content */}
        <div className="absolute inset-0 flex flex-col justify-center items-center gap-6 md:p-10 p-5 text-center">
          <span className="uppercase text-black md:text-4xl  text-md tracking-widest  font-black">
            WELCOME TO EVEREST HOSPITAL PVT.LTD
          </span>
          <h1 className="flex flex-wrap justify-center items-center gap-2 text-white md:text-xl text-xl font-light">
            Doctors:
            <span className="text-2xl font-semibold bg-primary-500 text-white px-6 rounded-2xl">
              <Typewriter
                options={{
                  strings: [
                    "Prof. Dr. Chandeshwar Mahaseth",
                    "Dr. Rajan Shah",
                    "Dr Subash Khadka",
                    "Dr. Jyotshna Sharma",
                    "Dr. Sunil Shrestha",
                    "Dr. Sushil Mohan Bhattari",
                    "Prof. Dr. Ram Kishor Sah",
                    "Prof. Dr. Dilip Sharma",
                    "Dr. Akhilesh Kumar Kashyap",
                    "Dr. Kajan Raj Shrestha",
                    "Dr.Chandan K Jayswal",
                    "Dr Prerna Jha",
                    "Dr. Abasesh Bhandari",
                    "Dr. Sanat Chalise",
                    "Dr. Anupama Thapa Basnet",
                    "Dr. Naresh Giri",
                    "Dr Roshan Pokhrel",
                    "Dr. Ram Kishor Sah",
                    "Dr. Bachhu Ram KC",
                    "Dr. Nabin Pokharel",
                    "Dr. Adheep Arun Shrestha",
                    "Dr Subharna Dhoj Thapa",
                  ],
                  autoStart: true,
                  loop: true,
                  delay: 75,
                }}
              />
            </span>
          </h1>
        </div>
      </div>

    </div>
  );
};

export default Welcome;
