// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { items } from "../data/sliderdata";

const Slider = () => {
  return (
    <div>
      <Swiper
        spaceBetween={1}
        centeredSlides={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={false}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="relative layout bg-gradient-to-br from-cyan-500/50 to-sky-200/50 md:h-[90vh] h-[60vh] w-full flex items-center justify-center">
              <img
                src={item.img}
                alt={item.name}
                className="object-cover  absolute w-full h-full mix-blend-overlay"
              />

              {/* <div className="flex flex-col justify-center items-center text-center">
                <h1 className="md:mt-0 mt-32 text-xl md:px-0 px-8 uppercase tracking-wider text-white md:text-5xl font-bold">
                  {item.name}
                </h1>
                <h1 className="animate-bounce duration-500 md:px-0 px-8 md:mt-5 mt-2 text-xl text-red-600 md:text-4xl font-semibold">
                  {item.desc}
                </h1>
              </div> */}
            </div>

          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
