import * as Image from "../images";
export const features = [
  {
    icon: Image.care,
    title: "Parent Care",
    desc: "One of the experienced private hospitals in Nepal",
  },
  {
    icon: Image.specialties,
    title: "Quality of Doctor",
    desc: "Laparoscopic Service    ",
  },

  {
    icon: Image.nurse,
    title: "Dedicated and  Trained Staff",
    desc: "Equal treatment for everyone; special discounted prices for impoverished and deprived people.",
  },

  {
    icon: Image.machine,
    title: "Advance Technology",
    desc: "Highly experienced, skilled Dedicated nursing and paramedical support, exclusive patient advice, and liaison team.",
  },

  {
    icon: Image.excellence,
    title: "Clinical Excellence",
    desc: "Socially conscious organization ",
  },

  {
    icon: Image.price,
    title: "Affortable Price",
    desc: "Best Center For Gastro    ",
  },
];
