import { format } from "date-fns";

export const formatDate = (dateTimeString: string) => {
  // Check if the date string is valid
  const date = new Date(dateTimeString);

  // Validate the date
  if (isNaN(date.getTime())) {
    console.error("Invalid date:", dateTimeString);
    return "Invalid Date"; // Return a fallback string or handle the error as needed
  }

  // If the date is valid, format it
  const formattedDate = format(date, "MMMM d, yyyy");
  return formattedDate;
};
