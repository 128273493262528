import * as Image from "../images";

export const teamprocess = [
  {
    id: 1,
    image: Image.Chandrasen,
    name: "Prof. Dr. Chandeshwar Mahaseth",
    title: "Department : Pediatrics",
  },
  {
    id: 2,
    image: Image.bachhu,
    name: "Prof. Dr. Bachhu Ram KC",
    title: "Department : Orthopedics",
  },

  {
    id: 3,
    image: Image.naresh,
    name: "Prof. Dr. Naresh Giri",
    title: "Department : Surgery",
  },

  {
    id: 4,
    image: Image.prerna,
    name: "Dr. Prerna Jha",
    title: "",
  },

  {
    id: 5,
    image: Image.sampuna,
    name: "Dr. Sampurna Maharjan",
    title: "Orthopaedic Surgeon",
  },
  {
    id: 6,
    image: Image.juotshna,
    name: "Dr. Jyotshna Sharma ",
    title: "Department : Obstetrics and Gynecology",
  },

  {
    id: 7,
    image: Image.rajan,
    name: "Dr. Rajan Shah",
    title: "Department : Gynecology",
  },
  {
    id: 8,
    image: Image.sunilshit,
    name: "Dr. Sunil Shrestha",
    title: "Department : Gestroenterology and hepatology",
  },

  {
    id: 9,
    image: Image.roshanphok,
    name: "Dr. Roshan Pokhrel",
    title: "Department : Anesthesiology",
  },
  {
    id: 10,
    image: Image.nirajnep,
    name: "Dr. Niraj Nepal",
    title: "Department : Internal Medicine",
  },
];
