import { useEffect, useState } from "react";
import { FaqSection, Spinner } from "../../components/import";

import { AxiosInstance } from "../../config";
// const image = "https://res.cloudinary.com/dubzpy7hn/image/upload/v1701766810/Everest/public/_DSC1529_mbgcje.jpg";

export default function Faq() {
  // fetch all
  const [images, setImages] = useState([]);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        setFetching(true);
        const res = await AxiosInstance.get("/faq-images");
        setImages(res.data.data);
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };
    fetchAll();
  }, []);

  return (
    <div className="layout">
      <FaqSection />
      <div className=" flex flex-col  gap-4">
        {images.map((item: any, index: any) => (
          <img
            key={index}
            src={item?.image}
            className=" mt-8"
            // style={{height:"80vh"}}
            alt="docter-checking-baby-in-everest-hospital"
          />
        ))}
        {fetching && (
          <div className=" flex items-center justify-center">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}
