import { useEffect, useState } from "react";
import Tilt from "react-parallax-tilt";
import { Link } from "react-router-dom";
import { AxiosInstance } from "../../config";
import { Heading } from "../../components/import";

export default function ExecutiveComittee() {
  const [comittees, setcomittees] = useState([]);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        const res = await AxiosInstance.get("/committees");
        setcomittees(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAll();
  }, []);

  return (
    <section className="layout md:px-10">
      <Heading title="Executive Comittee" />
      <div className="max-w-md">
        <p className="text-gray-600 mt-2">
          Leadership shaping vision, strategy, and organizational excellence
          with experienced guidance.
        </p>
      </div>
      <ul className="mt-16 grid gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {comittees?.map((item: any, idx) => (
          <Tilt key={idx} className="border rounded-lg">
            <Link
              to={`/executive-comittee/${item._id}`}
              className="flex items-start justify-between p-4"
            >
              <div className=" ">
                <div className=" flex mx-auto relative h-60  ">
                  <img
                    src={item?.image}
                    alt="img"
                    className=" object-contain"
                    height={1000}
                    width={1000}
                  />
                </div>
                <h4 className="text-gray-700 font-medium mt-2">{item.name}</h4>

                <p className="text-gray-600 text-sm">{item.position}</p>
              </div>
            </Link>
          </Tilt>
        ))}
      </ul>
    </section>
  );
}
