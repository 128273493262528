import { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import "react-quill/dist/quill.snow.css";
import { AxiosInstance } from "../../config";
import { Heading, Spinner } from "../../components/import";

interface notics {
  id: string;
  image: string;
  title: string;
  createdAt: string;
}

export default function Notice() {
  const [notices, setNotices] = useState<notics[]>([]);
  const [image, setImage] = useState<notics | null>(null);
  const [open, setOpen] = useState(false); // Modal open state

  useEffect(() => {
    const fetchAll = async () => {
      try {
        const res = await AxiosInstance.get("/notices");
        const sortedNotices = res.data.data.sort(
          (a: notics, b: notics) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setNotices(sortedNotices);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAll();
  }, []);

  // const fetchSingle = async (id: string) => {
  //   try {
  //     const res = await AxiosInstance.get(`/notices/${id}`);
  //     setImage(res.data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleImageClick = (imgUrl: string) => {
    setImage({
      id: image?.id || "",
      image: imgUrl,
      title: image?.title || "",
      createdAt: image?.createdAt || new Date().toISOString(),
    });
    setOpen(true);
  };

  return (
    <section className="layout">
      <Heading title="Our Latest Notices" />
      <div className="max-w-md">
        <p className="text-gray-600 mt-2">
          Stay informed with our latest notices, keeping you updated and engaged.
        </p>
      </div>

      <ul className="mt-16 grid gap-8 grid-cols-auto-fit-300">
        {notices?.map((item: notics, idx) => (
          <div key={idx} className="border-4 rounded-lg bg-gray-50">
            <div className="p-2 w-full h-full">
              <div className="h-48 w-full">
                <img
                  src={item?.image}
                  alt="img"
                  className="object-cover object-center w-full h-full cursor-pointer"
                  onClick={() => handleImageClick(item?.image)}
                />
              </div>
            </div>
          </div>
        ))}
      </ul>

      {/* Dialog modal for larger image view */}
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Overlay className="fixed inset-0 w-full h-full bg-black opacity-40" />
        <Dialog.Content className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-5xl mx-auto px-4">
          <div className="bg-white rounded-md shadow-lg px-4 py-6">

            <div className="mt-1 text-sm leading-relaxed text-gray-500">
              {image ? (
                <div>
                  <div className="relative h-48 md:h-72 lg:h-96 mt-20">
                    <img
                      src={image?.image}
                      alt="img"
                      className="object-contain w-full h-full"
                    />
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center">
                  <Spinner />
                </div>
              )}
            </div>



            <div className="items-center gap-2 mt-14 text-sm sm:flex">
              <Dialog.Close asChild className="w-full">
                <button
                  className="w-full mt-2 p-2.5 flex-1 bg-primary-500 text-white rounded-md outline-none border"
                  aria-label="Close"
                >
                  Close
                </button>
              </Dialog.Close>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Root>
    </section>
  );
}
