import * as Image from "../images";
export const items = [
  {
    img: Image.dipk,
    name: "Dr. Dipak Paudel",
    position: "Senior Endocrinologist",
    desc: "MBBS, MD (Intenal Medicine) , Fellowship in Endocrinology & Metabolism (SGRH, New Delhi,India",
    nmc: "7311",
    visitTime: "SUN,TUE,WED & FRI (4:30 PM - 6:00 PM)",
  },
  {
    img: Image.ajya,
    name: "Dr. Ajay Parajulee",
    position: "General And Laparoscopic Surgeon",
    desc: "MBBS, MS (General Surgery) , FMAS/DMAS Training in Minimal Access Surgery(WLH india)",
    nmc: "6665",
    visitTime: "SUN-FRI ((9:00 AM -11:00 AM) ",
  },
  {
    img: Image.pank,
    name: "Dr. Pankaj Deo",
    position: "Consultant Pulmonary,Critical Care And Sleep Medicine",
    desc: "MBBS, MD,NCCP (I)",
    nmc: "14814",
    visitTime: "SUN-FRI ((9:00 AM -11:00 AM) ",
  },
  {
    img: Image.chan,
    name: "Dr. Chandan K Jayswal",
    position: "Consultant Orthopaedic and Hand Surgeon",
    nmc: "9702",
    visitTime: "SUNDAY-FRIDAY ((4:00 PM -6:00 PM)",
  },

  {
    img: Image.arbin,
    name: "Dr. Arvind Bhusal",
    position: "Consultant  Neuropsychiatrist",
    desc: "MBBS, MD (KU)",
    nmc: "7007",
    visitTime: "SUNDAY-FRIDAY ((8:00 AM -9:00 AM)",
  },
  //   {
  //     img: Image.ban,
  //     name: "Dr. Bandana Jha",
  //     position: "Dermatology,Venereology & Leprosy",
  //     desc: "MBBS (KMCTH,KU) MD (BHU , India)",
  //     nmc: "12740",
  //     visitTime: "SUNDAY-FRIDAY ((3:00 PM -5:00 PM)",
  //   },
  {
    img: Image.raj,
    name: "Dr. Rajan Paudel",
    position: "Consultant  Cardlogogist",
    desc: "MBBS, MD (Intenal Medicine) , NAMS MD (Cardiology), NAMS/SGNHC",
    nmc: "5713",
    visitTime: "SUN-WED ((8:00 AM -9:00 AM) , MON- FRi (4:30 PM - 5:30 PM)",
  },
  {
    img: Image.pratap,
    name: "Dr. Pratap Narayan Prasad",
    position: "Senior consultant physician",
    desc: "MBBA,MD (internal medicine) , BPKISH Dharan Nepal DM (Nephrology), TUTH, IOM kathmandu Nepal",
    nmc: "962",
    visitTime: "SUN-FRI (8:00 AM - 8:30 AM) (6:00 PM -7:00 PM)",
  },
  {
    img: Image.rajanshah,
    name: "Dr. Rajan Shah",
    position: "Laparoscopic gynecologist and infertility specialist",
    desc: "Laparoscopic Gynecologist and Infertility Specialist",
    nmc: "16365",
    visitTime: "SUN-FRI (8:00 AM - 8:30 AM) (6:00 PM -7:00 PM)",
  },
  {
    img: Image.naresh,
    name: "Prof. Dr. Naresh Giri",
    position: "General Laparoscopic Surgeon",
    desc: "General Laparoscopic Surgeon",
    nmc: "1284",
    visitTime: "SUN-FRI (8:00 AM - 8:30 AM) (6:00 PM -7:00 PM)",
  },
  {
    img: Image.ProfDrChandeshwor,
    name: "Prof. Dr. Chandeswar Mahaseth",
    position: "Chief Consultant Pediatrician",
    desc: "Chief Consultant Pediatrician",
    nmc: "1357",
    visitTime: "SUN-FRI (8:00 AM - 8:30 AM) (6:00 PM -7:00 PM)",
  },
  {
    img: Image.DrNirajNepal,
    name: "Dr. Niraj Nepal",
    position: "Senior Consultant Physician",
    desc: " Senior Consultant Physician",
    nmc: "3535",
    visitTime: "SUN-FRI (8:00 AM - 8:30 AM) (6:00 PM -7:00 PM)",
  },
  {
    img: Image.Kajan,
    name: "Dr. Kajan Raj Shrestha",
    position: "Consultant Cardio Thoracic Vascular Surgeon (CTVS)",
    desc: " Consultant Cardio Thoracic Vascular Surgeon (CTVS)",
    nmc: "5376",
    visitTime: "SUN-FRI (8:00 AM - 8:30 AM) (6:00 PM -7:00 PM)",
  },
  {
    img: Image.SushilMohanBhattarai,
    name: "Dr. Sushil Mohan Bhattari",
    position: "Senior Consultant Neuro Surgeon",
    desc: "Senior Consultant Neuro Surgeon",
    nmc: "7770",
    visitTime: "SUN-FRI (8:00 AM - 8:30 AM) (6:00 PM -7:00 PM)",
  },
];
