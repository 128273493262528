import axios from "axios";
import { useState } from "react";

export const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "http://localhost:4000/api",
});

AxiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("accessToken");
  // config.headers.Authorization = `Bearer ${accessToken}`;
  config.headers.Authorization = accessToken;
  return config;
});
export const useAxiosLoader = () => {
  const [loading, setLoading] = useState(false);

  AxiosInstance.interceptors.request.use((config) => {
    setLoading(true);
    return config;
  });

  AxiosInstance.interceptors.response.use(
    (response) => {
      setLoading(false);
      return response;
    },
    (error) => {
      setLoading(false);
      return Promise.reject(error);
    }
  );

  return loading;
};
