import { features } from "../data/whyevesethospital";
import * as Image from "../images";


export default function WhyEverestHospital() {
  return (
    <section className="layout">
      <div className=" md:py-14  text-gray-600 gap-16 justify-between  lg:flex  ">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=""
        >
          <div className="max-w-xl space-y-3 ">
            <p className="text-primary-700 text-2xl font-black sm:text-3xl capitalize xl:text-4xl">
              Why Everest hospital?
            </p>
          </div>
          <ul className=" flex flex-col gap-4 mt-10">
            {features.map((item, idx) => (
              <li key={idx} className="">
                <div className="flex items-center gap-1">
                  <img src={item.icon} alt="img" className=" h-10 w-10 " />
                  <span className="text-[20px]">{item.title}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full lg:w-6/12 mt-12 lg:mt-0">
          <img
            src={Image.nur16}
            alt="img"
            height={800}
            width={800}
            className="  hover:animate-pulse rounded-se-3xl rounded-sm "
          />
        </div>
      </div>
    </section>
  );
}
