import { useState, useEffect } from "react";
import Slider from "react-slick";
import { AxiosInstance } from "../config";

interface Feature {
  image: string;
  name: string;
  description: string;
}

export default function FeaturesSection() {
  const [features, setFeatures] = useState<Feature[]>([]);

  useEffect(() => {
    const fetchAll = async () => {
      try {
        const res = await AxiosInstance.get("/facilities");
        setFeatures(res.data.data);
      } catch (error) {
        console.error("Error fetching features:", error);
      }
    };
    fetchAll();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [

      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <section className="layout py-14 text-gray-600">
      <div className="">
        <div className="relative max-w-2xl mx-auto text-center">
          <h3 className="text-gray-700 text-2xl font-semibold sm:text-4xl">
            Features of Everest Hospital
          </h3>
          <p className="mt-3">
            Explore Everest Hospital’s advanced medical services, expert care, and commitment to affordable, inclusive healthcare for all.
          </p>
        </div>
        <div className="relative mt-12">
          <Slider {...settings}>
            {features.map((item, idx) => (
              <div key={idx} className="px-2">
                <div className="group [perspective:1000px]  h-56 mx-auto">
                  <div className="relative w-full h-full transition-transform duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                    {/* Front Side */}
                    <div className="absolute w-full h-full  border border-primary-600 bg-white shadow-md rounded-lg flex flex-col items-center justify-center [backface-visibility:hidden]">
                      <div className="border border-primary-600 p-2 rounded-full box-shadow overflow-hidden">
                        <img src={item.image} className="h-16 w-16 object-cover" alt="Feature" />

                      </div>
                      <h4 className="text-gray-700 font-semibold text-center mt-3 px-2">{item.name}</h4>
                    </div>
                    {/* Back Side */}
                    <div className="absolute w-full h-full bg-primary-600 text-white rounded-lg flex items-center justify-center px-4 text-center [transform:rotateY(180deg)] [backface-visibility:hidden]">
                      <p className="text-sm leading-relaxed">{item.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}
