import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { AxiosInstance } from "../../config";
import { Heading } from "../../components/import";

interface video {
  createdAt: string;
}
export default function Video() {
  // fetch all
  const [images, setImages] = useState([]);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        const res = await AxiosInstance.get("/videos");
        const sortedVideo = res.data.data.sort((a: video, b: video) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        setImages(sortedVideo);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAll();
  }, []);

  const getEmbedUrl = (url: string) => {
    const videoId = url.split('/').pop();
    return `https://www.youtube.com/embed/${videoId}`;
  };

  return (
    <>
      <section className="layout">
        <Heading title="Videos" />
        <p className="w-full lg:w-6/12 text-gray-600 mt-2 tracking-wide leading-normal">
          Explore our comprehensive videos showcasing hospital facilities, dedicated staff, and impactful moments in healthcare excellence.
        </p>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-12">
          {images?.map((item: any) => (
            <div key={item._id} className="bg-gray-300">
              <iframe
                src={getEmbedUrl(item.url)}
                className="w-full h-80"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
