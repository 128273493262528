import { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { LeftBar, Spinner } from "../../components/import";
import toast from "react-hot-toast";
import { formatDate } from "../../helper/formatDate";
import { AxiosInstance } from "../../config";

export default function DashAppointment() {
  const [refetch, setRefetch] = useState(0);

  //delete
  const [deleting, setDeleting] = useState(false);
  const handleDelete = async (id: string) => {
    try {
      setDeleting(true);
      const res = await AxiosInstance.delete(`/appointments/${id}`);
      res.data.success && toast.success(res.data.msg);
      setDeleting(false);
      setRefetch(2);
    } catch (error) {
      setDeleting(false);
    }
  };

  // fetch all
  const [comittees, setcomittees] = useState([]);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        setRefetch(0);
        setFetching(true);
        const res = await AxiosInstance.get("/appointments");
        setcomittees(res.data.data);
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };
    fetchAll();
  }, [refetch]);
  const formatTime = (time24: string | undefined) => {
    // Check if time24 is defined and in the expected format
    if (!time24 || !/\d{2}:\d{2}/.test(time24)) {
      // Handle the case where time24 is not a string or doesn't match the expected format
      console.error("Invalid time format:", time24);
      return "Invalid time";
    }

    const [hours, minutes] = time24.split(":");
    // Check if hours and minutes are valid numbers
    if (isNaN(parseInt(hours, 10)) || isNaN(parseInt(minutes, 10))) {
      // Handle the case where hours or minutes are not valid numbers
      console.error("Invalid time values:", hours, minutes);
      return "Invalid time";
    }

    const formattedHours = parseInt(hours, 10) % 12 || 12;
    const period = parseInt(hours, 10) >= 12 ? "PM" : "AM";
    return `${formattedHours}:${minutes} ${period}`;
  };

  return (
    <div>
      <div className="flex gap-4 ">
        <div className=" w-2/12 shadow-md">
          <LeftBar />
        </div>

        <div className="w-10/12 shadow-md p-4">
          <div className=" ">
            <div className="items-start justify-between md:flex">
              <h3 className="max-w-lg text-gray-800 text-xl font-bold sm:text-2xl">Appointments</h3>
            </div>
            <div className="mt-12 shadow-sm border rounded-lg overflow-x-auto">
              <table className="w-full table-auto text-sm text-left">
                <thead className=" text-gray-600 font-medium border-b">
                  <tr>
                    <th className="py-3 px-6">S.N</th>
                    <th className="py-3 px-6 whitespace-nowrap">Full Name</th>
                    <th className="py-3 px-6">Phone</th>
                    <th className="py-3 px-6">Doctor</th>
                    <th className="py-3 px-6 whitespace-nowrap"> Appointment Day</th>
                    <th className="py-3 px-6 whitespace-nowrap">Appointment Time</th>
                    <th className="py-3 px-6">Created At</th>
                    <th className="py-3 px-6">Actions</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 divide-y">
                  {comittees?.map((item: any, idx: any) => (
                    <tr key={idx}>
                      <td className="px-6 py-4 whitespace-nowrap">{idx + 1} .</td>

                      <td className="px-6 py-4 whitespace-nowrap">{item?.fullName}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{item?.phone}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{item?.doctor?.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{item?.day}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{formatTime(item?.time)}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{formatDate(item?.createdAt)}</td>
                      <td className="px-6 whitespace-nowrap">
                        {/* <Link
                          to={`/dashboard/doctors/edit/${item._id}`}
                          // onClick={() => fetchSingle(item._id)}
                          className="y-2 px-3 font-medium text-yellow-600 hover:text-yellow-500 duration-150 hover:bg-gray-50 rounded-lg  ">
                          Edit
                        </Link> */}

                        <button
                          onClick={() => handleDelete(item._id)}
                          disabled={item.deleting}
                          className="py-2 leading-none px-3 font-medium text-red-600 hover:text-red-500 duration-150 hover:bg-gray-50 rounded-lg">
                          {deleting ? <Spinner /> : "Delete"}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className=" flex items-center justify-center my-5">{fetching && <Spinner />}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
