

import { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";

import { Link, useLocation } from "react-router-dom";
import { MoveLeft } from "lucide-react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { AxiosInstance } from "../../config";
import { LeftBar, Spinner } from "../../components/import";

export default function DashFacilityEdit() {
  const location = useLocation();
  const id = location.pathname.split("/")[4];
  const { register, watch } = useForm();
  const allInputField = watch();
  const [refetch, setRefetch] = useState(0);

  // get single
  const [singleItem, setSingleItem] = useState<any>();
  useEffect(() => {
    const fetchSingle = async () => {
      try {
        setRefetch(0);
        const res = await AxiosInstance.get(`/facilities/${id}`);
        setSingleItem(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSingle();
  }, [id, refetch]);

  const [Name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [updating, setUpdating] = useState(false);
  const handleUpdate = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", Name);
    formData.append("description", description);
    formData.append("image", allInputField.image[0]);
    try {
      setUpdating(true);
      const res = await AxiosInstance.patch(`/facilities/${id}`, formData);
      setUpdating(false);
      res.data.success && toast.success(res.data.msg);
      setRefetch(2);
    } catch (error) {
      console.error(error);
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (singleItem) {
      setName(singleItem.name || "");
      setDescription(singleItem.description || "");
    }
  }, [singleItem]);

  return (
    <div>
      <div className="flex gap-4 ">
        <div className=" w-2/12 shadow-md">
          <LeftBar />
        </div>

        <div className="w-10/12 shadow-md p-4 flex flex-col gap-8">
          <Link
            to={"/dashboard/facilities"}
            className=" flex items-center gap-1  text-sm">
            <MoveLeft /> Back
          </Link>
          <h3 className="max-w-lg text-gray-800 text-xl font-bold sm:text-2xl">Edit Facility</h3>

          {singleItem && (
            <form
              onSubmit={handleUpdate}
              className="">
              <div className="mt-1 text-sm leading-relaxed  text-gray-500">
                <div className=" grid grid-cols-2 gap-4">
                  <div>
                    <label className="font-medium ">Facilitiy Name</label>
                    <input
                      type="text"
                      required
                      onChange={(e) => setName(e.target.value)}
                      value={Name}
                      placeholder="New Facilitiy"
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>

                  <div>
                    <label className="font-medium ">New Image</label>
                    <div className=" flex items-center gap-2">
                      <input
                        type="file"
                        {...register("image", { required: true })}
                        className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                      />

                      {allInputField?.image && allInputField?.image[0] === undefined && (
                        <img
                          src={singleItem?.image}
                          className="w-12 h-12 rounded-md   border border-primary-100"
                          alt="Preview"
                          style={{ objectFit: "cover" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <label className="font-medium ">Description</label>
                  <textarea
                    required
                    defaultValue={singleItem?.description}
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    placeholder="Senior Consultant"
                    className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                  />
                </div>
              </div>

              <button
                disabled={updating}
                type="submit"
                className="w-full mt-2 p-2.5 flex-1 flex items-center justify-center text-white bg-primary-600 rounded-md outline-none ring-offset-2 ring-primary-600 focus:ring-2">
                {updating ? <Spinner /> : "Update"}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
