import { teamprocess } from "../data/process";
export default function Process() {
  return (
    <div className="layout  border-gray-200">
      <section data-aos-duration="3000" className="py-14 ">
        <div className="max-w-screen-xl flex  flex-col gap-6 mx-auto px-4 md:px-8">
          <div className="max-w-xl mx-auto sm:text-center">
            <h3 className=" text-[#1BBDE4] text-xl font-bold  ">Our Process</h3>
            <p className="text-gray-800 text-3xl font-semibold sm:text-4xl mt-3">
              How it helps you stay strong
            </p>
          </div>
          <ul className="grid gap-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 3xl:grid-cols-5 ">
            {teamprocess.map((item, idx) => (
              <li key={idx} className="flex flex-col justify-start items-center">
                <div className="group h-48 w-48 sm:h-40 md:h-56 md:w-56">
                  <img
                    src={item.image}
                    className="border-2 box-shadow border-gray-200 bg-white hover:scale-105 duration-700 relative w-full h-full object-cover object-center rounded-full"
                    alt=""
                  />
                  <span className="group-hover:scale-105 duration-700 md:-mt-56 -mt-48 md:ml-44 ml-32 sm:-mt-40 absolute rounded-full px-4 py-2 bg-[#1BBDE4] text-gray-100 text-xl font-semibold">
                    {item.id}
                  </span>
                </div>
                <div className="mt-4 text-center">
                  <h4 className="text-lg text-gray-700 font-semibold">
                    {item.name}
                  </h4>
                  <p className="text-[#AEB2BF] font-semibold">{item.title}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </div>
  );
}
