// import HeroSection from "../components/HeroSection";
// import Teams from "../components/Teams";
import * as Components from "../../components/import";
export default function Home() {
  return (
    <div className=" flex flex-col gap-2 md:gap-5 ">
      {/* <Components.HeroSection /> */}
      <Components.Slider />
      <Components.Welcome />
      <Components.Team />
      {/* <Components.Teams /> */}
      <Components.Choose />
      <Components.Started />
      <Components.Process />
      <Components.Information />
      <Components.HealthCare />
      <Components.HelpLine />
      <Components.FeatureSection />
      <Components.WhyEverestHospital />
      <Components.Testimonials />

    </div>
  );
}
