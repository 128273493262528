import { useEffect, useState } from "react";
import parse from "html-react-parser";
import { AxiosInstance } from "../../config";
import { Spinner, Heading } from "../../components/import";
import * as Image from "../../images";


export default function OurMissionVision() {
  const [comittees, setcomittees] = useState([]);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        setFetching(true);
        const res = await AxiosInstance.get("/mission-vision");
        setcomittees(res.data.data);
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };
    fetchAll();
  }, []);

  return (
    <div className="layout  px-4 md:flex md:px-10 gap-14  items-start layout">
      <div className="hover:scale-105 cursor-pointer duration-500 mb-16  bg-[#CFCDCA] rounded-2xl">
        <img
          className=" cursor-pointer rounded-2xl w-[700px]"
          src={Image.dipak}
          alt=""
        />{" "}
      </div>

      <div className="">
        <Heading title="Our Mission , Visions & Goals" />
        {comittees?.map((item: any) => (
          <div key={item._id} className="text-gray-600 p-2 text-justify">
            {parse(item?.description || "")}
          </div>
        ))}

        {fetching && (
          <div className=" flex items-center justify-center ">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}
