import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation } from "react-router-dom";
import { MoveLeft } from "lucide-react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { AxiosInstance } from "../../config";
import { LeftBar, Spinner } from "../../components/import";

export default function DashDoctorEdit() {
  const location = useLocation();
  const id = location.pathname.split("/")[4];
  const { register, watch } = useForm();
  const allInputField = watch();
  const [refetch, setRefetch] = useState(0);

  // get single
  const [singleItem, setSingleItem] = useState<any>();
  useEffect(() => {
    const fetchSingle = async () => {
      try {
        setRefetch(0);
        const res = await AxiosInstance.get(`/doctors/${id}`);
        setSingleItem(res.data.data);
        setValue(res.data.data?.description || "");
      } catch (error) {
        console.log(error);
      }
    };
    fetchSingle();
  }, [id, refetch]);

  const [Name, setName] = useState(singleItem?.name);
  const [position, setPosition] = useState("");
  const [value, setValue] = useState("");
  const [department, setDepartment] = useState("");
  const [sunday, setSunday] = useState("");
  const [monday, setMonday] = useState("");
  const [wednesday, setWednesday] = useState("");
  const [thursday, setThursday] = useState("");
  const [friday, setFriday] = useState("");
  const [saturday, setSaturday] = useState("");
  const [onlineAppointment, setOnlineAppointment] = useState("");
  const [availableWholeWeek, setAvailableWholeWeek] = useState("");
  const [nmc, setNmc] = useState<any>();
  // const [tuesday, setTuesday] = useState();

  const [updating, setUpdating] = useState(false);
  const handleUpdate = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", Name);
    formData.append("position", position);
    formData.append("department", department);
    formData.append("sunday", sunday);
    formData.append("monday", monday);
    // formData.append("tuesday", tuesday);
    formData.append("wednesday", wednesday);
    formData.append("thursday", thursday);
    formData.append("friday", friday);
    formData.append("saturday", saturday);
    formData.append("nmc", nmc);
    formData.append("onlineAppointment", onlineAppointment);
    formData.append("availableWholeWeek", availableWholeWeek);
    formData.append("description", value);
    formData.append("image", allInputField.image[0]);
    try {
      setUpdating(true);
      const res = await AxiosInstance.patch(`/doctors/${id}`, formData);
      setUpdating(false);
      res.data.success && toast.success(res.data.msg);
      setRefetch(2);
    } catch (error) {
      console.error(error);
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (singleItem) {
      setName(singleItem.name || "");
      setPosition(singleItem.position || "");
      setValue(singleItem.description || "");
      setDepartment(singleItem.department || "");
      setSunday(singleItem.sunday || "");
      setMonday(singleItem.monday || "");
      setWednesday(singleItem.wednesday || "");
      setThursday(singleItem.thursday || "");
      setFriday(singleItem.friday || "");
      setSaturday(singleItem.saturday || "");
      setOnlineAppointment(singleItem.onlineAppointment || "");
      setAvailableWholeWeek(singleItem.availableWholeWeek || "");
      setNmc(singleItem.nmc);
    }
  }, [singleItem]);
  console.log(onlineAppointment);

  return (
    <div>
      <div className="flex gap-4 ">
        <div className=" w-2/12 shadow-md">
          <LeftBar />
        </div>

        <div className="w-10/12 shadow-md p-4 flex flex-col gap-8">
          <Link
            to={"/dashboard/doctors"}
            className=" flex items-center gap-1  text-sm">
            <MoveLeft /> Back
          </Link>
          <h3 className="max-w-lg text-gray-800 text-xl font-bold sm:text-2xl">Edit Doctor</h3>

          {singleItem && (
            <form
              onSubmit={handleUpdate}
              className="">
              <div className="mt-1 text-sm leading-relaxed  text-gray-500">
                <div className=" grid grid-cols-2 gap-4">
                  <div>
                    <label className="font-medium ">Doctor Name</label>
                    <input
                      type="text"
                      required
                      onChange={(e) => setName(e.target.value)}
                      value={Name}
                      placeholder="Rajan Shahi"
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>

                  <div>
                    <label className="font-medium ">Department</label>
                    <input
                      type="text"
                      required
                      onChange={(e) => setDepartment(e.target.value)}
                      value={department}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>

                  <div>
                    <label className="font-medium ">Position</label>
                    <input
                      type="text"
                      required
                      defaultValue={singleItem?.position}
                      onChange={(e) => setPosition(e.target.value)}
                      value={position}
                      placeholder="Senior Consultant"
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>

                  <div>
                    <label className="font-medium ">NMC Number</label>
                    <input
                      type="number"
                      defaultValue={singleItem?.nmc}
                      onChange={(e) => setNmc(e.target.value)}
                      value={nmc}
                      placeholder="536"
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>

                  <div>
                    <label className="font-medium ">New Image</label>
                    <div className=" flex items-center gap-2">
                      <input
                        type="file"
                        {...register("image", { required: true })}
                        className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                      />

                      {allInputField?.image && allInputField?.image[0] === undefined && (
                        <img
                          src={singleItem?.image}
                          className="w-12 h-12 rounded-md   border border-primary-100"
                          alt="Preview"
                          style={{ objectFit: "cover" }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className=" mt-4">
                  <p className=" font-medium text-yellow-500  text-xs">Note :</p>
                  <p className=" text-xs">1. Leave the filed empty if doesnot available on that day </p>
                  <p className=" text-xs">2. Always write in 24 hour format 10:00-16:30 </p>
                </div>

                <div className=" grid grid-cols-4 gap-4 mt-4">
                  <div>
                    <label className=" ">Sunday</label>
                    <input
                      type="text"
                      placeholder="10:00-16:00"
                      onChange={(e) => setSunday(e.target.value)}
                      value={sunday}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>

                  <div>
                    <label className=" ">Monday</label>
                    <input
                      type="text"
                      placeholder="10:00-16:00"
                      onChange={(e) => setMonday(e.target.value)}
                      value={monday}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>

                  <div>
                    <label className=" ">Wednesday</label>
                    <input
                      type="text"
                      placeholder="10:00-16:00"
                      onChange={(e) => setWednesday(e.target.value)}
                      value={wednesday}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>

                  <div>
                    <label className=" ">Thursday</label>
                    <input
                      type="text"
                      placeholder="10:00-16:00"
                      onChange={(e) => setThursday(e.target.value)}
                      value={thursday}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>

                  <div>
                    <label className=" ">Friday</label>
                    <input
                      type="text"
                      placeholder="10:00-16:00"
                      onChange={(e) => setFriday(e.target.value)}
                      value={friday}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>

                  <div>
                    <label className=" ">Saturday</label>
                    <input
                      type="text"
                      placeholder="10:00-16:00"
                      onChange={(e) => setSaturday(e.target.value)}
                      value={saturday}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>
                </div>
              </div>

              <div className=" flex items-center gap-1 mt-4 text-gray-700">
                <select
                  className=" text-xs cursor-pointer px-4"
                  onChange={(e) => setAvailableWholeWeek(e.target.value)}
                  value={availableWholeWeek}>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                <p className=" text-xs">Doctor Availability (sunday-friday : 10:00AM to 6:00PM )</p>
              </div>

              <div className=" flex items-center gap-1 mt-4 text-gray-700">
                <select
                  className=" text-xs cursor-pointer px-4"
                  onChange={(e) => setOnlineAppointment(e.target.value)}
                  value={onlineAppointment}>
                  <option
                    value="yes"
                    className=" ">
                    Yes
                  </option>
                  <option value="no">No</option>
                </select>
                <p className=" text-xs">Doctor is Available for Online Appointment</p>
              </div>

              <div className="  mt-4">
                <label className="font-medium ">Description</label>
                <div className=" h-96">
                  <ReactQuill
                    theme="snow"
                    value={value}
                    defaultValue={singleItem?.description}
                    onChange={setValue}
                    className=" h-72 mt-2"
                  />
                </div>
              </div>

              <button
                disabled={updating}
                type="submit"
                className="w-full mt-2 p-2.5 flex-1 flex items-center justify-center text-white bg-primary-600 rounded-md outline-none ring-offset-2 ring-primary-600 focus:ring-2">
                {updating ? <Spinner /> : "Update"}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
