import Slider from "react-slick";
import { items } from "../data/team";

export default function Team() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 2500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <div className="layout px-4 py-10 ">
      <Slider {...settings}>
        {items.map((item, index) => (
          <div key={index} className="px-2">
            <div className="border-2 bg-transparent justify-between flex flex-col gap-4 shadow-lg group hover:shadow-2xl duration-500 px-2 py-2 rounded-md h-[500px]">
              <img
                className="h-40 md:h-56 w-full object-contain md:object-cover cursor-pointer rounded-xl"
                src={item.img}
                alt={item.name}
              />
              <div className="flex flex-col gap-2 overflow-hidden">
                <p className="group-hover:underline duration-500 text-xl md:text-2xl text-red-800 font-semibold">
                  {item.name}
                </p>
                <p className="text-gray-900 text-sm md:text-[18px]">{item.position}</p>
                {/* <p className="text-gray-700 text-sm md:text-base line-clamp-3">
                  {item.desc}
                </p> */}
                <p className="text-gray-500 text-sm md:text-base">NMC No: {item.nmc}</p>
                <p className="mt-auto text-yellow-500 font-bold text-sm md:text-base">
                  VISITING TIME:
                </p>
              </div>
              <button className="bg-primary-500 hover:bg-primary-400 duration-500 text-sm text-gray-100 py-2 rounded-md">
                {item.visitTime}
              </button>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
