import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import * as Image from "./images";
import { LogOut } from "lucide-react";
import { useContext, useEffect } from "react";
import { CharityContext } from "./context/context";
import AOS from "aos";
import "aos/dist/aos.css";
import { Navbar, Footer } from "./components/import";
import * as Page from "./pages/import";
import "./App.css"
import Emergency from "./components/ui/Emergency";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];


  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    navigate("/dashboard");
    toast.success("Logout success");
    window.location.reload();
  };

  // useEffect(() => {
  //   const isTokenPresent = localStorage.getItem("accessToken");
  //   console.log(isTokenPresent);
  // }, []);

  const { isAuthenticated } = useContext(CharityContext);
  return (
    <>
      <div>
        {pathname !== "dashboard" && (
          <div className=" fixed w-full bg-white z-50">
            <Navbar />
          </div>
        )}

        {isAuthenticated && (
          <>
            {pathname === "dashboard" && (
              <div className="shadow-md p-2 flex justify-between items-center">
                <div>
                  <div className="flex gap-2 items-center space-x-2  p-2 rounded-lg">
                    <div className="w-[100px] h-[80px] flex-shrink-0">
                      <img
                        src={Image.logoev}
                        alt="Everest Hospital Logo"
                        className="w-full h-full object-fill"
                      />
                    </div>

                    {/* Text Content */}
                    <div className="flex flex-col justify-evenly -gap-2 h-full text-justify">
                      <span className="text-red-600 font-bold text-xl">
                        एभरेष्ट अस्पताल प्रा.  लि.
                      </span>
                      <span className="text-primary-500 font-black text-[28px]">
                        EVEREST
                      </span>
                      <span className="bg-red-600 px-1 font-bold py-1 text-white text-[17px]">
                        HOSPITAL PVT. LTD.
                      </span>
                    </div>
                  </div>
                  <p className=" text-lg font-bold tracking-wider uppercase text-gray-700">
                    Dashboard
                  </p>
                </div>

                <div
                  onClick={handleLogout}
                  className=" cursor-pointer mr-4  md:mr-20"
                >
                  <LogOut />
                </div>
              </div>
            )}
          </>
        )}

        <div
          className={`${pathname === "dashboard" ? "w-full  mt-12 m-2 border-t" : pathname === "" ? " w-full px-2 3xl:px-0 pt-16 3xl:pt-24 overflow-x-hidden " : " overflow-x-hidden w-full  mx-auto px-4 3xl:px-0 pt-16 3xl:pt-[200px]"
            }`}
        >
          {pathname !== "dashboard" && (
            <Emergency />
          )}

          <Routes >
            <Route path="*" element={<Page.NotFound />} />
            <Route path="/" element={<Page.Home />} />
            <Route path="/about" element={<Page.About />} />
            <Route path="/image" element={<Page.Gallery />} />

            <Route path="/video" element={<Page.Video />} />

            <Route path="/our-facilities" element={<Page.OurFacilities />} />

            <Route path="/our-core-values" element={<Page.OurColeValues />} />

            <Route path="/our-mission-vision" element={<Page.OurMissionVision />} />
            <Route path="/faq" element={<Page.Faq />} />

            <Route path="/health-packages" element={<Page.HealthPackages />} />

            <Route path="/appointment/:id" element={<Page.Appointment />} />

            <Route path="/news" element={<Page.News />} />
            <Route path="/events" element={<Page.Event />} />

            <Route path="/events/:id" element={<Page.SingleEvent />} />

            <Route
              path="/executive-comittee/:id"
              element={<Page.SingleExecutiveComittee />}
            />

            <Route path="/departments" element={<Page.Departments />} />
            {/* <Route
              path="/consultants"
              element={<Consultants />}
            /> */}

            <Route path="/consultants" element={<Page.ConsultantsNew />} />
            <Route path="/contact" element={<Page.Contact />} />
            <Route path="/executive-comittee" element={<Page.ExecutiveComittee />} />

            <Route path="/consultants/:id" element={<Page.SingleDoctor />} />

            <Route path="/departments/:id" element={<Page.SingleDepartment />} />

            <Route path="/accrediations" element={<Page.Accrediation />} />

            <Route path="/awards" element={<Page.Award />} />

            <Route path="/notices" element={<Page.Notice />} />

            <Route path="/dashboard" element={<Page.Dashboard />} />

            {isAuthenticated && (
              <>
                <Route path="/dashboard/comittees" element={<Page.DashComittee />} />

                <Route
                  path="/dashboard/comittees/edit/:id"
                  element={<Page.DashComitteeEdit />}
                />

                <Route path="/dashboard/galleries" element={<Page.DashGallery />} />

                <Route
                  path="/dashboard/accrediations"
                  element={<Page.DashAccrediation />}
                />

                <Route
                  path="/dashboard/accrediations/edit/:id"
                  element={<Page.DashAccrediationEdit />}
                />

                <Route path="/dashboard/doctors" element={<Page.DashDoctor />} />

                <Route
                  path="/dashboard/doctors/edit/:id"
                  element={<Page.DashDoctorEdit />}
                />

                <Route path="/dashboard/faqs" element={<Page.DashFaq />} />

                <Route
                  path="/dashboard/faqs/edit/:id"
                  element={<Page.DashFaqEdit />}
                />

                <Route
                  path="/dashboard/faqs/faqs-images"
                  element={<Page.DashFaqImage />}
                />

                <Route
                  path="/dashboard/contacts"
                  element={<Page.DashContactDetail />}
                />

                <Route
                  path="/dashboard/contacts/edit/:id"
                  element={<Page.DashContactDetailEdit />}
                />

                <Route
                  path="/dashboard/departments"
                  element={<Page.DashDepartment />}
                />

                <Route
                  path="/dashboard/departments/edit/:id"
                  element={<Page.DashDepartmentEdit />}
                />

                <Route
                  path="/dashboard/departments/add-doctors/:id"
                  element={<Page.DashDepartmentAddDoctor />}
                />

                <Route path="/dashboard/awards" element={<Page.DashAward />} />

                <Route
                  path="/dashboard/awards/edit/:id"
                  element={<Page.DashAwardEdit />}
                />

                <Route path="/dashboard/notices" element={<Page.DashNotice />} />

                <Route path="/dashboard/events" element={<Page.DashEvent />} />

                <Route
                  path="/dashboard/events/edit/:id"
                  element={<Page.DashEventEdit />}
                />

                <Route
                  path="/dashboard/testimonials"
                  element={<Page.DashTestimonial />}
                />

                <Route
                  path="/dashboard/testimonials/edit/:id"
                  element={<Page.DashTestimonialEdit />}
                />

                <Route path="/dashboard/news" element={<Page.DashNews />} />

                <Route
                  path="/dashboard/news/edit/:id"
                  element={<Page.DashNewsEdit />}
                />

                <Route path="/dashboard/abouts" element={<Page.DashAbout />} />

                <Route
                  path="/dashboard/abouts/edit/:id"
                  element={<Page.DashAboutEdit />}
                />

                <Route
                  path="/dashboard/facilities"
                  element={<Page.DashFacility />}
                />

                <Route
                  path="/dashboard/facilities/edit/:id"
                  element={<Page.DashFacilityEdit />}
                />

                <Route path="/dashboard/values" element={<Page.DashValue />} />

                <Route
                  path="/dashboard/values/edit/:id"
                  element={<Page.DashValueEdit />}
                />

                <Route
                  path="/dashboard/appointments"
                  element={<Page.DashAppointment />}
                />

                <Route path="/dashboard/packages" element={<Page.DashPackage />} />

                <Route
                  path="/dashboard/packages/edit/:id"
                  element={<Page.DashPackageEdit />}
                />

                <Route
                  path="/dashboard/vision"
                  element={<Page.DashMissionVision />}
                />

                <Route
                  path="/dashboard/vision/edit/:id"
                  element={<Page.DashVisionEdit />}
                />

                <Route path="/dashboard/video" element={<Page.DashVideo />} />
              </>
            )}
          </Routes>
        </div>
        <Toaster position="top-right" reverseOrder={false} />

        {pathname !== "dashboard" && (
          <div className="pt-14 ">
            <Footer />
          </div>
        )}
      </div>
    </>
  );
};

export default App;
