import { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation } from "react-router-dom";
import { MoveLeft } from "lucide-react";
import { useForm } from "react-hook-form";
import { Spinner, LeftBar } from "../../components/import";
import toast from "react-hot-toast";
import { AxiosInstance } from "../../config";

export default function DashPackageEdit() {
  const location = useLocation();
  const id = location.pathname.split("/")[4];
  const { register, watch } = useForm();
  const allInputField = watch();
  const [refetch, setRefetch] = useState(0);

  // get single
  const [singleItem, setSingleItem] = useState<any>();
  useEffect(() => {
    const fetchSingle = async () => {
      try {
        setRefetch(0);
        const res = await AxiosInstance.get(`/health-packages/${id}`);
        setSingleItem(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSingle();
  }, [id, refetch]);

  const [updating, setUpdating] = useState(false);
  const handleUpdate = async (e: any) => {
    e.preventDefault();

    try {
      setUpdating(false);
      const res = await AxiosInstance.patch(`/health-packages/${id}`, allInputField);
      setUpdating(false);
      res.data.success && toast.success(res.data.msg);
      setRefetch(2);
    } catch (error) {
      console.error(error);
      setUpdating(false);
    }
  };

  return (
    <div>
      <div className="flex gap-4 ">
        <div className=" w-2/12 shadow-md">
          <LeftBar />
        </div>

        <div className="w-10/12 shadow-md p-4 flex flex-col gap-8">
          <Link
            to={"/dashboard/packages"}
            className=" flex items-center gap-1  text-sm">
            <MoveLeft /> Back
          </Link>
          <h3 className="max-w-lg text-gray-800 text-xl font-bold sm:text-2xl">Edit Health Package</h3>

          {singleItem && (
            <form
              onSubmit={handleUpdate}
              className="">
              <div className="mt-1 text-sm leading-relaxed  text-gray-500">
                <div className=" grid grid-cols-2 gap-4 ">
                  <div>
                    <label className="font-medium ">Title</label>
                    <input
                      defaultValue={singleItem.title}
                      type="text"
                      {...register("title", { required: true })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>
                  <div>
                    <label className="font-medium ">Total Rate</label>
                    <input
                      defaultValue={singleItem.totalRate}
                      type="number"
                      {...register("totalRate", { required: true })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>
                  <div>
                    <label className="font-medium ">Package Rate</label>
                    <input
                      defaultValue={singleItem.packageRate}
                      type="number"
                      {...register("packageRate", { required: true })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>
                  <div>
                    <label className="font-medium ">Order</label>
                    <input

                      type="number"
                      defaultValue={singleItem.order}
                      {...register("order", { required: true })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>
                  <div>
                    <label className="font-medium ">Item 1</label>
                    <input
                      defaultValue={singleItem.item1}
                      type="text"
                      {...register("item1", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>
                  <div>
                    <label className="font-medium ">Item 2</label>
                    <input
                      defaultValue={singleItem.item2}
                      type="text"
                      {...register("item2", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>
                  <div>
                    <label className="font-medium ">Item 3</label>
                    <input
                      defaultValue={singleItem.item3}
                      type="text"
                      {...register("item3", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>
                  <div>
                    <label className="font-medium ">Item 4</label>
                    <input
                      defaultValue={singleItem.item4}
                      type="text"
                      {...register("item4", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>{" "}
                  <div>
                    <label className="font-medium ">Item 5</label>
                    <input
                      defaultValue={singleItem.item5}
                      type="text"
                      {...register("item5", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>{" "}
                  <div>
                    <label className="font-medium ">Item 6</label>
                    <input
                      defaultValue={singleItem.item6}
                      type="text"
                      {...register("item6", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>{" "}
                  <div>
                    <label className="font-medium ">Item 7</label>
                    <input
                      defaultValue={singleItem.item7}
                      type="text"
                      {...register("item7", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>{" "}
                  <div>
                    <label className="font-medium ">Item 8</label>
                    <input
                      defaultValue={singleItem.item8}
                      type="text"
                      {...register("item8", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>{" "}
                  <div>
                    <label className="font-medium ">Item 9</label>
                    <input
                      defaultValue={singleItem.item9}
                      type="text"
                      {...register("item9", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>{" "}
                  <div>
                    <label className="font-medium ">Item 10</label>
                    <input
                      defaultValue={singleItem.item10}
                      type="text"
                      {...register("item10", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>{" "}
                  <div>
                    <label className="font-medium ">Item 11</label>
                    <input
                      defaultValue={singleItem.item11}
                      type="text"
                      {...register("item11", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>{" "}
                  <div>
                    <label className="font-medium ">Item 12</label>
                    <input
                      defaultValue={singleItem.item12}
                      type="text"
                      {...register("item12", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>{" "}
                  <div>
                    <label className="font-medium ">Item 13</label>
                    <input
                      defaultValue={singleItem.item13}
                      type="text"
                      {...register("item13", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>{" "}
                  <div>
                    <label className="font-medium ">Item 14</label>
                    <input
                      defaultValue={singleItem.item14}
                      type="text"
                      {...register("item14", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>{" "}
                  <div>
                    <label className="font-medium ">Item 15</label>
                    <input
                      defaultValue={singleItem.item15}
                      type="text"
                      {...register("item15", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>{" "}
                  <div>
                    <label className="font-medium ">Item 16</label>
                    <input
                      defaultValue={singleItem.item16}
                      type="text"
                      {...register("item16", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>{" "}
                  <div>
                    <label className="font-medium ">Item 17</label>
                    <input
                      defaultValue={singleItem.item17}
                      type="text"
                      {...register("item17", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>{" "}
                  <div>
                    <label className="font-medium ">Item 18</label>
                    <input
                      defaultValue={singleItem.item18}
                      type="text"
                      {...register("item18", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>{" "}
                  <div>
                    <label className="font-medium ">Item 19</label>
                    <input
                      defaultValue={singleItem.item19}
                      type="text"
                      {...register("item19", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>{" "}
                  <div>
                    <label className="font-medium ">Item 20</label>
                    <input
                      defaultValue={singleItem.item20}
                      type="text"
                      {...register("item20", { required: false })}
                      className="w-full  px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary-600 shadow-sm rounded-lg"
                    />
                  </div>{" "}
                </div>
              </div>

              <button
                disabled={updating}
                defaultValue={singleItem.title}
                type="submit"
                className="w-full mt-2 p-2.5 flex-1 flex items-center justify-center text-white bg-primary-600 rounded-md outline-none ring-offset-2 ring-primary-600 focus:ring-2">
                {updating ? <Spinner /> : "Update"}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
