import React, { useContext, useState } from 'react';
import { FaAmbulance, FaTimes } from 'react-icons/fa';
import { CharityContext } from '../../context/context';
import { BookA, Phone, Plane, Siren, Stethoscope, LucideIcon } from 'lucide-react';

interface ContactInfoItemProps {
     title: string;
     phoneNumber: string | undefined;
     Icon: LucideIcon; // Update the type here to use LucideIcon
}

const ContactInfoItem: React.FC<ContactInfoItemProps> = ({ title, phoneNumber, Icon }) => {
     return (
          <div className="flex items-center text-black w-full">
               <Icon className="text-xl text-red-600" size={18} />
               <div className="ml-3">
                    <h3 className="font-bold text-red-600">{title}</h3>
                    {phoneNumber && (
                         <p className="text-lg">
                              <a href={`tel:${phoneNumber}`} className="text-sm text-black group-hover:text-gray-800 mt-1">
                                   {phoneNumber}
                              </a>
                         </p>
                    )}
               </div>
          </div>
     );
};

const Emergency = () => {
     const [isModalOpen, setIsModalOpen] = useState(false);
     const { contactDetail } = useContext(CharityContext);

     // Function to toggle the modal
     const toggleModal = () => {
          setIsModalOpen(!isModalOpen);
     };

     if (!contactDetail) {
          return null; // Return nothing if contact details are not available
     }

     return (
          <div>
               {/* Emergency Button */}
               <button
                    onClick={toggleModal}
                    className="fixed flex  justify-center items-center bottom-4 right-4 bg-red-500 text-white p-2 md:p-4 z-50 rounded-full shadow-lg zoom-in-out-element"
               >
                    <FaAmbulance className='text-sm md:text-xl' />
               </button>

               {/* Modal */}
               {isModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                         <div className="bg-[#fff] p-10 rounded-[2px] shadow-lg w-auto h-auto relative">
                              <button
                                   onClick={toggleModal}
                                   className="absolute top-6 right-10 text-red-500 "
                              >
                                   <FaTimes size={24} />
                              </button>
                              <div className="flex flex-col justify-center items-center text-center ">
                                   <h2 className="text-xl md:text-4xl font-bold text-red-600">Emergency Help</h2>
                                   <p className="my-4">This is an emergency modal. You can add any content or form here.</p>

                                   {/* Contact Number Section */}
                                   <div className="grid grid-cols-2 gap-6 items-center">
                                        <ContactInfoItem
                                             title="Emergency Hotline"
                                             phoneNumber={contactDetail.emergencyHotline}
                                             Icon={Siren}
                                        />
                                        <ContactInfoItem
                                             title="Emergency Ambulance Hotline"
                                             phoneNumber={contactDetail.emergencyAmbulanceHotline}
                                             Icon={Siren}
                                        />
                                        <ContactInfoItem
                                             title="Hospital Number"
                                             phoneNumber={contactDetail.hospitalNumber}
                                             Icon={Phone}
                                        />
                                        <ContactInfoItem
                                             title="Heli Rescue Number"
                                             phoneNumber={contactDetail.heliRescueNumber}
                                             Icon={Plane}
                                        />
                                        <ContactInfoItem
                                             title="Appointment Number"
                                             phoneNumber={contactDetail.appointmentNumber}
                                             Icon={BookA}
                                        />
                                        <ContactInfoItem
                                             title="Administration Number"
                                             phoneNumber={contactDetail.administrationNumber}
                                             Icon={Stethoscope}
                                        />
                                   </div>
                              </div>
                         </div>
                    </div>
               )}

          </div>
     );
};

export default Emergency;
