import * as Image from "../images";

export const contents = [
  {
    id: 6000,
    name: "One of the leading",
    desc: "healthcare provider in nepal",
  },

  {
    id: 250,
    name: "33+ Medical specialities",
    desc: "hunder one roof",
  },

  {
    id: 200,
    name: "33+ Medical specialities",
    desc: "Amenities",
  },
  {
    id: 500,
    name: "Blood Bank",
  },
  {
    id: 99999,
    name: "2 Million+ Patients Treated",
  },
];

export const whyChoosedata = [
  {
    img: Image.whyChoose1,
  },
  {
    img: Image.whyChoose2,
  },
  {
    img: Image.whyChoose3,
  },
  {
    img: Image.whyChoose4,
  },
  {
    img: Image.whyChoose5,
  },
  {
    img: Image.whyChoose6,
  },
  {
    img: Image.whyChoose7,
  },
  {
    img: Image.whyChoose8,
  },
  {
    img: Image.whyChoose9,
  },
  {
    img: Image.whyChoose10,
  },
  {
    img: Image.whyChoose11,
  },
  {
    img: Image.whyChoose12,
  },
  {
    img: Image.whyChoose13,
  },
];
