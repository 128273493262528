
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import { MoveLeft } from "lucide-react";
import { AxiosInstance } from "../../config";

export default function SingleEvent() {
  const location = useLocation();
  const id = location.pathname.split("/")[2];

  const [singleItem, setSingleItem] = useState<any>();
  useEffect(() => {
    const fetchSingle = async () => {
      try {
        const res = await AxiosInstance.get(`/events/${id}`);
        setSingleItem(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSingle();
  }, [id]);

  return (
    <div className="layout">
      {/* <Link
        to={"/events"}
        className=" flex items-center gap-1  text-sm mb-4">
        <MoveLeft /> Back
      </Link> */}
      <div className=" flex justify-center shadow p-4 rounded-sm ">
        <img
          src={singleItem?.image}
          alt="img"
          className=""
        />
      </div>

      <div className=" flex flex-col  items-center mt-2 ">
        <p className=" text-center text-xl font-medium  text-primary-500 tracking-wider">{singleItem?.title}</p>
      </div>

      <div className=" mt-8 shadow-md p-4 md:p-8">
        <p className=" text-gray-600 ">{parse(singleItem?.description || "")} </p>
      </div>

      {/* <Link
        to={"/events"}
        className=" flex items-center gap-1  text-sm mt-8 justify-center">
        <MoveLeft /> Back
      </Link> */}
    </div>
  );
}
