import { Link } from "react-router-dom";
import nur17 from "../assets/nurse/nur19.jpg";
import { contents } from "../data/choose";
import CountUp from "react-countup";

const Choose = () => {
  return (
    <div className=" flex  flex-col gap-16 justify-center items-center py-10   md:px-0 px-4  layout">
      <div className=" md:flex flex-col gap-2 md:gap-6 items-center w-full">
        <h1 className=" md:text-4xl text-2xl font-bold   text-primary-600 cursor-pointer  hover:scale-105 duration-500">
          WHY CHOOSE EVEREST{" "}
        </h1>
        <div

          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 3xl:grid-cols-5  justify-between gap-8"
        >
          {contents.map((content, index) => (
            <div key={index} className=" flex flex-col gap-1">
              <span className=" md:text-4xl text-3xl flex  text-primary-600 cursor-pointer border-b-2 w-32 p-2 border-gray-300">
                <CountUp start={0} end={content.id} duration={9} separator="," />
                +
              </span>
              <span className="text-sm md:text-xl text-gray-700 cursor-pointer">
                {content.name}
              </span>
              <span className=" text-md text-gray-600 cursor-pointer hover:text-gray-400 duration-500 ">
                {content.desc}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="  flex justify-center items-center">
        <div className=" md:flex justify-evenly items-start  w-full ">
          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            className=" md:w-5/12"
          >
            <img
              className=" object-cover rounded-xl cursor-pointer"
              src={nur17}
              alt=""
            />
          </div>
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
            className=" flex flex-col  gap-8 md:w-5/12"
          >
            <span className=" text-primary-600 font-bold md:text-3xl text-2xl md:mt-0 mt-10">
              PROVIDING HOMELY CARE TO PATIENTS
            </span>
            <span className=" text-sm md:text-lg text-justify">
              PROVIDING HOMELY CARE TO PATIENTS Everest provides an extensive
              array of medical services aimed at ensuring a comfortable and
              stress-free experience for individuals seeking care. Our adept and
              multilingual medical professionals, equipped with high-level
              training, deliver personalised and compassionate healthcare
              tailored to meet the unique needs of each patient. We facilitate
              seamless access by accepting a diverse range of international
              medical insurances and implementing direct billing arrangements.
              In Nepal, our overarching objective is to furnish you with
              world-class medical care and unwavering support.
            </span>
            <Link to={"/about"}>
              <button className="text-xl bg-primary-600 text-white py-2 md:px-8  px-4 w-max hover:scale-110 duration-500">
                LEARN MORE
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Choose;

