import { Facebook, Mail, Youtube } from "lucide-react";

import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="bg-primary-500 text-white ">
      <div className=" py-5 w-full mx-auto px-4  md:px-0 layout ">
        <div className="gap-6 grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4 md:px-10 ">


          {/* <div className="w-full md:w-6/12">
            <div className="w-full">
              <Link
                to={"/"}
                className=" flex gap-2   items-center"
              >
                <img src={Image.logoev} alt="logo" className=" h-9   rounded-md" />
                <h1 className=" text-gray-100  font-serif  md:text-xl">
                  EVEREST HOSPITAL PVT.LTD
                </h1>
              </Link>
            </div>

            <div className="mt-6 ">
              <ul className="flex items-center space-x-4">
                {socialLinks.map((item, idx) => (
                  <li
                    key={idx}
                    className="w-10 h-10 border rounded-full flex items-center justify-center"
                  >
                    <Link to={item.href} target="_blank" aria-label={item.name}>
                      {item.icon}
                    </Link>
                  </li>
                ))}
              </ul>

            </div>
          </div> */}
          {footerNavs.map((item, idx) => (
            <ul className="space-y-4 " key={idx}>
              <h4 className="text-white font-medium text-lg">{item.label}</h4>

              {item.items.map((el: any, idx: number) => (
                <li key={idx}>
                  <Link
                    target={
                      el.type === "location" ||
                        el.type === "youtube" ||
                        el.type === "facebook" ||
                        el.type === "mail"
                        ? "_blank"
                        : ""
                    }
                    to={
                      el.type === "mail"
                        ? `mailto:${el.name}`
                        : el.type === "phone"
                          ? `tel:${el.name}`
                          : el.href
                    }
                    className="hover:underline  flex items-center gap-1"
                  >
                    {el.icon} {el.name}
                  </Link>
                </li>
              ))}
            </ul>
          ))}
          <div className="w-full flex justify-end">
            <div className="w-full 3xl:max-w-[300px] ">
              <iframe
                title="Everest Hospital Pvt. Ltd."
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.9319449993122!2d85.332863!3d27.688498199999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19bdde43f10d%3A0x9b829a41b660f0d8!2sEverest%20Hospital%20Pvt.%20Ltd.!5e0!3m2!1sen!2snp!4v1742960911443!5m2!1sen!2snp"
                className="w-full h-full border-0 rounded-lg"
                allowFullScreen
                loading="lazy"
              ></iframe>
            </div>
          </div>

        </div>
        <div className="mt-2">
          <hr />
          <div className=" text-center py-4 ">
            &copy; {new Date().getFullYear()} Everest Hospital pvt. ltd. All rights reserved.
          </div>
        </div>
        <p className=" text-xs text-end">
          Developed by –
          <span
            className=" cursor-pointer hover:underline"
          >
            Clouds Nepal Web
          </span>
        </p>
      </div>
    </footer>
  );
}

const footerNavs = [
  {
    label: "Resources",
    items: [
      {
        href: "/consultants",
        name: "Consultants",
      },
      {
        href: "/executive-comittee",
        name: "Comittee",
      },

      {
        href: "/faq",
        name: "FAQ",
      },
    ],
  },
  {
    label: "Quick Links",
    items: [
      {
        href: "/",
        name: "Home",
      },

      {
        href: "/about",
        name: "About",
      },
      {
        href: "/gallery",
        name: "Gallery",
      },
    ],
  },
  {
    label: "Contact Us",
    items: [
      // {
      //   icon: <MapPin size={18} />,
      //   href: "https://maps.app.goo.gl/D6Tpua3R9QbyMjjr9",
      //   name: "New Baneshwor, Kathmandu",
      //   type: "location",
      // },

      {
        icon: <Mail size={18} />,
        name: "everesthospital@gmail.com",
        type: "mail",
      },
      {
        icon: <Facebook size={18} />,
        href: "https://www.facebook.com/people/Everest-Hospital/100054280850242/",
        name: "Follow us on facebook",
        type: "facebook",
      },

      {
        icon: <Youtube size={18} />,
        href: "https://www.youtube.com/@everest_hospital",
        name: "Subscribe us on youtube",
        type: "youtube",
      },
    ],
  },
];


// const socialLinks = [
//   {
//     href: "https://www.facebook.com/people/Everest-Hospital/100054280850242/",
//     icon: <Facebook />,
//     name: "Facebook",
//   },
//   {
//     href: "https://www.youtube.com/@everest_hospital",
//     icon: <Youtube />,
//     name: "YouTube",
//   },
//   {
//     href: "https://www.instagram.com/everesthospital",
//     icon: <Instagram />,
//     name: "Instagram",
//   },
// ];