import { useEffect, useState } from "react";
import { AxiosInstance } from "../config";
import Slider from "react-slick";

export default function Testimonials() {
  // fetch all
  const [comittees, setComittees] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setFetching(true);
        const res = await AxiosInstance.get("/testimonials");
        setComittees(res.data.data);
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };
    fetchAll();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  return (
    <section className="layout">
      <div className=" mx-auto px-4 md:px-8 ">
        <div className=" mx-auto text-center ">
          <h3 className="text-primary-500 font-semibold pb-6">What people are saying</h3>
          <Slider {...settings}>
            {comittees.map((item: any, idx) => (
              <li key={idx}>
                <p className="text-gray-700 text-sm font-medium sm:text-2xl">“{item.description} “</p>
                <div className="flex items-center justify-between flex-col mt-4">
                  <p className="bg-gray-100 h-10 w-10 rounded-full flex items-center justify-center font-medium text-gray-700">
                    {item.name.charAt(0)}
                  </p>
                  <div className="mt-3">
                    <span className="block text-gray-700 font-semibold">{item.name}</span>
                    <span className="block text-gray-600 text-sm mt-0.5">{item.title}</span>
                  </div>
                </div>
              </li>
            ))}
          </Slider>
        </div>
      </div>
    </section >
  );
}
