
import { useEffect, useRef, useState } from "react";
import { AxiosInstance } from "../config";
const FaqsCard = (props: any) => {
  const answerElRef = useRef<any>();
  const [state, setState] = useState(false);
  const [answerH, setAnswerH] = useState("0px");
  const { faqsList, idx } = props;

  const handleOpenAnswer = () => {
    const answerElH = answerElRef.current.childNodes[0].offsetHeight;
    setState(!state);
    setAnswerH(`${answerElH + 20}px`);
  };

  return (
    <div
      className="space-y-3 mt-5 overflow-hidden border-b"
      key={idx}
      onClick={handleOpenAnswer}>
      <h4 className="cursor-pointer pb-5 flex items-center justify-between text-lg text-gray-700 font-medium">
        {faqsList.question}
        {state ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-500 ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M20 12H4"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-500 ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 4v16m8-8H4"
            />
          </svg>
        )}
      </h4>
      <div
        ref={answerElRef}
        className="duration-300"
        style={state ? { height: answerH } : { height: "0px" }}>
        <div>
          <p className="text-gray-500">{faqsList.answer}</p>
        </div>
      </div>
    </div>
  );
};

export default function FaqSection() {
  // const faqs = [
  //   {
  //     q: "How do I schedule an appointment at the hospital?",
  //     a: "Scheduling an appointment is easy. You can call our appointment desk at 01-4795177 during business hours or use our online appointment booking system on our website. Select your preferred date and time, and you'll receive a confirmation.",
  //   },
  //   {
  //     q: "What emergency services does the hospital provide?",
  //     a: "We offer 24/7 emergency services for critical medical situations. Our Emergency Department is equipped to handle a wide range of emergencies, and our skilled medical team is always ready to provide prompt and compassionate care.",
  //   },
  //   {
  //     q: "How can I access my medical records?",
  //     a: "Accessing your medical records is simple. You can request a copy of your records in person at our Medical Records Department or submit a request through our secure online portal. We prioritize the confidentiality and security of your health information.",
  //   },
  //   {
  //     q: "What insurance plans does the hospital accept?",
  //     a: "We accept a variety of insurance plans to make healthcare accessible. Please check our website or contact our Billing Department for the most up-to-date information on accepted insurance providers. We are committed to working with you to ensure a seamless billing process.",
  //   },
  //   {
  //     q: "How can I provide feedback about my experience at the hospital?",
  //     a: "Your feedback is valuable to us. You can share your experience by filling out our online feedback form available on our website. Additionally, you can contact our Patient Relations Department to discuss any concerns or compliments. We appreciate your input as we strive to continually improve our services.",
  //   },
  // ];

  // fetch all
  const [faqs, setFaqs] = useState([]);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        setFetching(true);
        const res = await AxiosInstance.get("/faqs");
        setFaqs(res.data.data);
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };
    fetchAll();
  }, []);

  console.log(fetching)


  return (
    <section className="">
      <div className="space-y-3 text-start">
        <h1 className="text-3xl text-gray-700 font-semibold">Frequently Asked Questions</h1>
        <p className="text-gray-600 max-w-lg  text-lg">Answered all frequently asked questions, Still confused? feel free to contact us.</p>
      </div>
      <div className="mt-16">
        {faqs.map((item, idx) => (
          <FaqsCard
            idx={idx}
            key={idx}
            faqsList={item}
          />
        ))}
      </div>
    </section>
  );
}
