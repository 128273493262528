import { useEffect, useState } from "react";
import { AxiosInstance } from "../../config";
import { Heading } from "../../components/import";

interface award {
  createdAt: string;
}

export default function Award() {
  const [comittees, setcomittees] = useState([]);
  useEffect(() => {
    const fetchAll = async () => {
      try {
        const res = await AxiosInstance.get("/awards");
        const sortedAward = res.data.data.sort((a: award, b: award) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        setcomittees(sortedAward);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAll();
  }, []);

  return (
    <section className="layout">
      <Heading title="Our Awards & Recognition" />
      <div className="max-w-md">
        <p className="text-gray-600 mt-2">Explore our impressive collection of awards, recognizing excellence and dedication.</p>
      </div>
      <ul className="mt-16 grid gap-8  grid-cols-1 sm:grid-cols-2 md:grid-cols-3  3xl:grid-cols-4">
        {comittees?.map((item: any, idx) => (
          <div
            key={idx}
            className="border rounded-lg bg-gray-50">
            <div className="p-4">
              <div className=" h-48">
                <img
                  src={item?.image}
                  alt="img"
                  className="object-contain w-full h-full"
                />
              </div>
              <hr className="my-2" />
              <h4 className="text-gray-700 mt-2 text-center">{item.title}</h4>
            </div>
          </div>
        ))}
      </ul>
    </section>
  );
}

