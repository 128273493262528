import { useEffect, useState } from "react";
import { formatDate } from "../../helper/formatDate";
import { Link } from "react-router-dom";
import { AxiosInstance } from "../../config";
import { Heading } from "../../components/import";

export default function News() {
  const [comittees, setcomittees] = useState([]);

  useEffect(() => {
    const fetchAll = async () => {
      try {
        const res = await AxiosInstance.get("/news");
        const sortedNews = res.data.data.sort((a: any, b: any) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });
        setcomittees(sortedNews);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAll();
  }, []);

  return (
    <section className="layout">
      <Heading title="Our Latest News" />
      <div className="max-w-md">
        <p className="text-gray-600 mt-2">Discover and join our latest events, where community and engagement thrive.</p>
      </div>
      <ul className="mt-16 grid gap-2 grid-cols-auto-fit-300">
        {comittees?.map((item: any, idx) => (
          <Link
            to={`${item.urllink}`} target="_blank"
            key={idx}
            className="border rounded-lg shadow-md">
            <div className="p-4">
              <div className="relative h-full">
                <img
                  src={item?.image}
                  alt="img"
                  className="w-full h-[200px] object-cover rounded-lg"
                />

              </div>
              <hr className="my-2" />
              <h4 className="text-gray-600 mt-2 text-xs text-end ">{formatDate(item.createdAt)}</h4>
              <h4 className="text-gray-800 mt-1 text-lg line-clamp-2 ">{item.name}</h4>
            </div>
          </Link>
        ))}
      </ul>
    </section>
  );
}
